import Contacts from "@material-ui/icons/Contacts";
import React from "react";
import {
  BooleanInput,
  FormDataConsumer,
  FormTab,
  TextInput,
  number,
  required,
  regex,
  useTranslate,
} from "react-admin";
import { Grid } from "@mui/material";
import { FieldLabel, GroupContent } from "./SettingsStyle";

export const SecuritySettings = {
  ENABLE_REQUIRED_TWO_FACTOR: "ENABLE_REQUIRED_TWO_FACTOR",
  TTL_KEY_SHARE: "TTL_KEY_SHARE",
  ALLOW_TO_EXPORT_CHAT: "ALLOW_TO_EXPORT_CHAT",
  FORCE_UPDATE_APP_SYNCHRONIZATION: "FORCE_UPDATE_APP_SYNCHRONIZATION",
};

export const TwoFactorSettings = props => {
  const translate = useTranslate();
  return (
    <FormTab
      {...props}
      label="resources.settings.tabs.security"
      icon={<Contacts />}
    >
      <FormDataConsumer>
        {({ formData, ..._ }) => {
          return <Grid container width="100%" spacing={2}>
            <Grid item xs={8} md={6} lg={4}>
              <FieldLabel>
                {translate("resources.settings.fields.force_auto_update_synchronization_label")}
              </FieldLabel>
              <GroupContent>
                <BooleanInput
                  source={SecuritySettings.FORCE_UPDATE_APP_SYNCHRONIZATION}
                  label="resources.settings.fields.force_auto_update_synchronization"
                  fullWidth
                />
              </GroupContent>
              <FieldLabel>
                {translate("resources.settings.fields.two_factor_label")}
              </FieldLabel>
              <GroupContent>
                <BooleanInput
                  source={SecuritySettings.ENABLE_REQUIRED_TWO_FACTOR}
                  label="resources.settings.fields.two_factor_enable"
                  fullWidth
                />
              </GroupContent>
              <FieldLabel>
                {translate("resources.settings.fields.room_label")}
              </FieldLabel>
              <GroupContent>
                <BooleanInput
                  source={SecuritySettings.ALLOW_TO_EXPORT_CHAT}
                  label="resources.settings.fields.allow_to_export_chat"
                  fullWidth
                />
              </GroupContent>
              <FieldLabel>
                {translate("resources.settings.fields.user_label")}
              </FieldLabel>
              <GroupContent>
                <TextInput
                  source={SecuritySettings.TTL_KEY_SHARE}
                  label="resources.settings.fields.ttl_key_share"
                  variant="outlined"
                  type="number"
                  fullWidth
                  validate={[
                    required(),
                    number(),
                    regex(/^[1-9]\d{0,5}$/, "resources.settings.validations.regex_integer"),
                  ]}
                />
              </GroupContent>
            </Grid>
          </Grid>;
        }}
      </FormDataConsumer>
    </FormTab>
  );
};
