import ChatPinIcon from "@material-ui/icons/Chat";
import {
  BooleanInput,
  email,
  FormDataConsumer,
  FormTab,
  required,
  PasswordInput,
  TextInput,
} from "react-admin";
import React from "react";

export const OTPSettings = (props) => {
  const requiredTelegramOptions = (value, form) => {
    return form.SEND_OTP_VIA_TELEGRAM && !value
      ? "resources.settings.validations.required_telegram_options"
      : undefined;
  };

  const requiredTwilioOptions = (value, form) => {
    return form.SEND_OTP_VIA_TWILIO && !value
      ? "resources.settings.validations.required_twilio_options"
      : undefined;
  };

  return <FormTab
    {...props}
    label="resources.settings.tabs.otp"
    icon={<ChatPinIcon />}
  >
    <BooleanInput
      source="SEND_OTP_VIA_TELEGRAM"
      label="resources.settings.fields.send_otp_telegram"
    />
    <FormDataConsumer>
      {
        ({ formData }) =>
          formData.SEND_OTP_VIA_TELEGRAM && (
            <>
              <TextInput
                source="TELEGRAM_API_BOT_URL"
                label="resources.settings.fields.telegram_bot_url"
                variant="outlined"
                fullWidth
                validate={requiredTelegramOptions}
              />
              <TextInput
                source="TELEGRAM_CHAT_GROUP_ID"
                label="resources.settings.fields.telegram_group_id"
                variant="outlined"
                fullWidth
                validate={requiredTelegramOptions}
              />
            </>
          )
      }
    </FormDataConsumer>

    <BooleanInput
      source="SEND_OTP_VIA_TWILIO"
      label="resources.settings.fields.send_otp_twilio"
    />
    <FormDataConsumer>
      {
        ({ formData }) =>
          formData.SEND_OTP_VIA_TWILIO && (
            <>
              <TextInput
                source="TWILIO_ACCOUNT_SID"
                label="resources.settings.fields.twilio_account_sid"
                variant="outlined"
                fullWidth
                validate={requiredTwilioOptions}
              />
              <TextInput
                source="TWILIO_AUTH_TOKEN"
                label="resources.settings.fields.twilio_auth_token"
                variant="outlined"
                fullWidth
                validate={requiredTwilioOptions}
              />
              <TextInput
                source="TWILIO_PHONE"
                label="resources.settings.fields.twilio_phone"
                variant="outlined"
                fullWidth
                validate={requiredTwilioOptions}
              />
            </>
          )
      }
    </FormDataConsumer>

    <BooleanInput
      source="SEND_OTP_VIA_EMAIL"
      label="resources.settings.fields.send_otp_email"
    />
    <FormDataConsumer>
      {
        ({ formData }) =>
          formData.SEND_OTP_VIA_EMAIL && (
            <>
              <TextInput
                source="SMTP_MAIL_HOST"
                label="resources.settings.fields.smtp_host"
                variant="outlined"
                helperText="Eg: smtp.gmail.com"
                fullWidth
                validate={required()}
              />
              <TextInput
                source="SMTP_MAIL_USER"
                label="resources.settings.fields.smtp_username"
                variant="outlined"
                fullWidth
                validate={[required(), email()]}
              />
              <PasswordInput
                source="SMTP_MAIL_PASSWORD"
                label="resources.settings.fields.smtp_password"
                variant="outlined"
                fullWidth
                validate={required()}
              />
              <TextInput
                source="SMTP_MAIL_FROM"
                label="resources.settings.fields.smtp_send_from"
                variant="outlined"
                helperText="Eg: no-reply@example.com"
                fullWidth
                validate={[required(), email()]}
              />
            </>
          )
      }
    </FormDataConsumer>
  </FormTab>
}
