import React from "react";
import { AdminContext, resolveBrowserLocale } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import authProvider from "./synapse/authProvider";
import dataProvider from "./synapse/dataProvider";
import germanMessages from "./i18n/de";
import englishMessages from "./i18n/en";
import chineseMessages from "./i18n/zh";
import vietnameseMessages from "./i18n/vi";
import permissionsReducer from "./reducers/permissionsReducer";
import * as permissionsSaga from "./sagas/permissionsSaga";
import AsyncResources from "./AsyncResources";

// TODO: Can we use lazy loading together with browser locale?
const messages = {
  de: germanMessages,
  en: englishMessages,
  zh: chineseMessages,
  vi: vietnameseMessages,
};
const getLocale = () => {
  const locale = localStorage.getItem("locale");
  return locale || resolveBrowserLocale();
};
const i18nProvider = polyglotI18nProvider(
  locale => (messages[locale] ? messages[locale] : messages.en),
  getLocale()
);

function App() {
  return (
    <AdminContext
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      customReducers={{ permissions: permissionsReducer }}
      customSagas={[permissionsSaga.permissionsSaga]}
    >
      <AsyncResources />
    </AdminContext>
  );
}

export default App;
