import { useState } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    TopToolbar,
    CreateButton,
    Filter,
    SearchInput,
    useRecordContext,
    useTranslate,
    Confirm,
    useNotify,
    useRedirect,
    useRefresh,
    SelectInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Avatar from "@material-ui/core/Avatar";
import FormControlLabel from '@mui/material/FormControlLabel';
import AntSwitch from '../common/AntSwitch';
import DeleteIcon from '@material-ui/icons/Delete';
import { LoadingProgress } from '../common/LoadingProgress';
import botProvider from '../../synapse/botProvider';

const choices = [
    { id: "true", name: "Enabled" },
    { id: "false", name: "Disabled" },
  ]

const date_format = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
};

const useStyles = makeStyles({
    search: {
        width: '300px',
        textAlign: 'left',
    },
    topToolbarBox: {
        paddingTop: '8px',
        alignItems: 'center'
    }
});

const CardActionsGrid = props => {
    const record = useRecordContext(props);
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClickDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenConfirm(true)
    };
    const handleDialogClose = () => setOpenConfirm(false);
    const handleConfirm = () => {
        setIsLoading(true);
        botProvider.deleteBot(record.id).then(() => {
            notify("resources.bots.actions.delete_success", { type: "success" });
            setOpenConfirm(false);
            setIsLoading(false);
            redirect(false);
            refresh()
        }).catch(error => {
            notify(error.message, { type: "error" });
            setOpenConfirm(false);
            setIsLoading(false);
        });
    };

    const onClickEdit = () => {
        // DataGrid.rowClick did it
        // window.location = `/#/bots/${props.record.id}`
    }

    return (
        <TopToolbar
            className={useStyles().topToolbarBox}
            style={{ justifyContent: 'flex-start', width: "90px" }}
        >
            <IconButton aria-label="edit" onClick={onClickEdit}>
                <EditIcon />
            </IconButton>
            {
                !record?.isEnabled &&
                <>
                    {
                        isLoading ?
                        <LoadingProgress style={{ marginLeft: '7px' }} size={20} thickness={3}/> :
                        <IconButton aria-label="delete" onClick={handleClickDelete} style={{ color: "#f44336" }}>
                            <DeleteIcon />
                        </IconButton>
                    }
                </>
            }
            <Confirm
                isOpen={openConfirm}
                loading={isLoading}
                title={`Delete Bot #${record?.name}`}
                content="Are you sure you want to delete this item?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </TopToolbar>
    );
}

const CardStatusGrid = props => {
    const record = useRecordContext(props);
    const notify = useNotify();
    const redirect = useRedirect();

    const [openConfirm, setOpenConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const refresh = useRefresh();

    const handleChangeStatus = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenConfirm(true);
    };
    const handleDialogClose = () => setOpenConfirm(false);
    const handleConfirm = () => {
        setIsLoading(true);
        botProvider.updateBot({ id: record.id, isEnabled: !record.isEnabled }).then(() => {
            notify("resources.bots.actions.change_status_success", { type: "success" });
            setOpenConfirm(false);
            setIsLoading(false);
            refresh();
            redirect(false);
        }).catch(error => {
            notify(error.message, { type: "error" });
            setOpenConfirm(false);
            setIsLoading(false);
        });
    };

    return (
        <TopToolbar
            className={useStyles().topToolbarBox}
            style={{ justifyContent: isLoading ? 'flex-start' : 'center', paddingLeft: isLoading ? '5px' : null, width: "70px" }}
        >
            {
                isLoading ?
                <LoadingProgress size={20} thickness={3}/> :
                <FormControlLabel
                    control={<AntSwitch sx={{ m: 1 }}
                                        checked={record?.isEnabled}
                                        onClick={handleChangeStatus}
                    />}
                />
            }
            
            <Confirm
                isOpen={openConfirm}
                loading={isLoading}
                title={`${!record?.isEnabled ? "Enable" : "Disable"} Bot #${record?.name}`}
                content={`Are you sure you want to ${!record?.isEnabled ? "enable" : "disable"} this item?`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </TopToolbar>
    );
}
CardStatusGrid.defaultProps = { label: "resources.bots.list.status", textAlign: 'left' };

const ActionsBar = () => (
    <TopToolbar >
        <CreateButton label="resources.bots.actions.add_bot"/>
    </TopToolbar>
);

const BotFilter = props => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
          <SearchInput
            alwaysOn
            className={useStyles().search}
            source="search"
            variant="outlined"
            placeholder={
                translate("resources.bots.list.search_placeholder")
            }
          />
          <SelectInput
            source="enable"
            label="Status"
            variant="outlined"
            choices={choices}
            resettable={false}
            emptyText="All"
            emptyValue="All"
            alwaysOn
         />
        </Filter>
    );
};

const AvatarField = ({ source, className, record = {} }) => (
    <Avatar src={record[source]} className={className} />
);

export const BotList = props => {
    const classes = useStyles();
    const styleOverflow = {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "200px",
        display: "inline-block"
      }
      
    return (
        <List
            {...props}
            filters={<BotFilter />}
            sort={{ field: "createdAt", order: "DESC" }}
            bulkActionButtons={false}
            actions={<ActionsBar />}
        >
            <Datagrid rowClick="edit">
                <AvatarField
                    label="resources.bots.list.avatar"
                    source="avatarSrc"
                    className={classes.small}
                    sortable={false}
                />
                <TextField 
                    source="botId" 
                    label="resources.bots.list.bot_id"
                />

                <TextField 
                    style={styleOverflow}
                    source="name" 
                    label="resources.bots.list.displayname"
                
                />
                <TextField
                    style={styleOverflow}
                    source="description" 
                    label="resources.bots.list.description"
                />
                <DateField
                    source="createdAt"
                    label="resources.bots.list.createdAt"
                    showTime
                    options={date_format}
                />
                <CardStatusGrid/>
                <CardActionsGrid/>
            </Datagrid>
        </List>
    );
};
