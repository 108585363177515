import React, { useState, useEffect } from "react";
import {
    useRefresh,
    useTranslate,
    useNotify,
    Loading,
    Title,
    Toolbar,
    TabbedForm,
    FormTab,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import roleProvider from "../../synapse/roleProvider";
import { Error as ErrorComponent } from "../Error";
import { logger } from '../../utils/logger';
import { RolePermissions } from "./RolePermissions";
import { RoleUsers } from "./RoleUsers";

const useStyles = makeStyles({
    deleteButton: {
        color: "#f44336",
        border: "1px solid #f44336",
        marginLeft: "20px",
    },
    saveButton: {
        color: "#fafafa",
        border: "1px solid #3f51b5",
        background: "#3f51b5",
        "&:hover": {
            backgroundColor: "#3f51b5bd",
        },
    },
});

const validateName = (value) => {
    if (!value.length) {
        return 'resources.roles.validations.required';
    }
    if (value.length > 64 ||
        (/^\s*$/.test(value?.trim())) ||
        (/^\s|\s$/.test(value))
    ) {
        return 'resources.roles.validations.invalid_name';
    }
    return '';
}

const DeleteRole = props => {
    const { roleId } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    
    const [loading, setLoading] = useState(false);
    const [isOpenDeleteRole, setIsOpenDeleteRole] = React.useState(false);

    const onConfirmDelete = () => {
        setIsOpenDeleteRole(true);
    }
    
    const onCloseDelete = () => {
        setIsOpenDeleteRole(false);
    }

    const onDelete = () => {
        setLoading(true);
        roleProvider.deleteRole(roleId).then(() => {
            notify("resources.roles.notifications.delete_success");
            window.location = '/#/roles';
            
        }).catch(error => {
            logger.error(error.message);
            notify(
              "resources.roles.notifications.delete_failed",
              { type: "error" },
            );
        }).finally(() => {
            setIsOpenDeleteRole(false);
            refresh();
            setLoading(false);
        });
    }

    return (
        <>
            <Button 
                variant="outlined" 
                size="medium" 
                className={classes.deleteButton}
                onClick={onConfirmDelete}
            >
                <DeleteIcon />
                {translate('resources.roles.actions.delete')}
            </Button>
            <Dialog onClose={onCloseDelete} aria-labelledby="reset-dialog-title" open={isOpenDeleteRole}>
                <DialogTitle id="reset-dialog-title">
                    {
                        translate('resources.roles.validations.delete_title')
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="reset-dialog-description">
                        {translate('resources.roles.validations.delete_content')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseDelete} color="inherit" label="Cancel">
                        <CancelIcon />
                        {translate('resources.roles.actions.cancel')}
                    </Button>
                    {
                        loading ?
                        <CircularProgress
                            style={{ height: "20px", width: "20px" }}
                            color="primary"
                        /> :
                        <Button onClick={onDelete} color="primary" label="Confirm">
                            <CheckIcon />
                            {translate('resources.roles.actions.confirm')}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </>     
    );
}

const RoleToolbar = ({isEditRole, savingRole, id, isInvalid, ...props}) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Toolbar {...props}>
            <Button 
                variant="outlined" 
                size="medium" 
                className={!isInvalid && !savingRole && classes.saveButton}
                disabled={isInvalid || savingRole}
                onClick={props.onSave}
            >
                <SaveIcon />
                {translate('resources.roles.actions.save')}
            </Button>
            {
                isEditRole ? 
                <DeleteRole 
                    roleId={id}
                />
                :
                <></>
            }
        </Toolbar>
    );
}

export const RoleDetails = props => { 
    const { id } = props;
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const isEditRole = !!id;
    const [loadingPage, setLoadingPage] = useState(isEditRole);
    const [saving, setSaving] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [messageInvalid, setMessageInvalid] = useState('');
    const [error, setError] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [users, setUsers] = useState([]);
    const [role, setRole] = useState({
        name: '',
        users: [],
        permissions: [],
    });

    const onNameChange = (value) => {
        const valueInput = value;
        setRole((state) => ({
            ...state,
            'name': valueInput,
        }));
        setValidate(valueInput);
    }

    const setValidate = (value) => {
        setIsInvalid(!!validateName(value));
        setMessageInvalid(validateName(value));
    }

    const handleError = (e) => {
        setIsInvalid(true);
        if (e.body?.errcode === 'M_ROLE_NAME_DUPLICATED') {
            setMessageInvalid('resources.roles.validations.existed');
        }
        else if (e.body?.errcode === 'M_ROLE_DATA_OUT_OF_DATE') {
            setMessageInvalid('resources.roles.validations.out_of_date');
        } else {
            notify(
                "resources.roles.notifications.save_failed",
                { type: "error" },
            );
        }
    }

    const onPermissionsChange = (permissions) => {
        setRole({
            ...role,
            'permissions': permissions,
        });
    }

    const onUsersChange = (users) => {
        setRole({
            ...role,
            'users': users,
        });
    }

    const onSave = () => {
        if (!!validateName(role.name)) {
            return setValidate(role.name);
        }
        setSaving(true);
        if (isEditRole) {
            roleProvider.updateRole(role).then(() => {
                notify("resources.roles.notifications.save_success");
                window.location = '/#/roles';
                refresh();
            })
            .catch(error => {
                logger.error(error.message);
                handleError(error);                
            })
            .finally(() => {
                setSaving(false);
            });
        } else {
            roleProvider.createRole(role).then(() => {
                notify("resources.roles.notifications.save_success");
                window.location = '/#/roles';
                refresh();
            })
            .catch(error => {
                logger.error(error.message);
                handleError(error);                
            })
            .finally(() => {
                setSaving(false);
            });
        }
    };
    
    useEffect(() => {
        roleProvider.getPermissions().then((result) => {
            setPermissions(result.data.map(data => ({
                code: data.code,
                name: translate(`permissions.${data.code}`),
            })));
        }).catch(error => {
            notify(error.message, { type: "error" });
        });

        roleProvider.getUsers(isEditRole ? id : null).then((result) => {
            setUsers(result.data);
        }).catch(error => {
            notify(error.message, { type: "error" });
        });

        if(isEditRole) {
            roleProvider.getRole(id).then((result) => {
                setRole({...result.data});
            }).catch(error => {
                notify(error.message, { type: "error" });
                setError(error);
            }).finally(() => {
                setLoadingPage(false);
            });
        }
    // eslint-disable-next-line 
    }, []);

    if (loadingPage) return <Loading />;
    if (error) return <ErrorComponent error={error} />;

    return[
        <Title defaultTitle={
            isEditRole ? `${translate("resources.roles.title.edit")} "${role.name}"`
            : translate("resources.roles.title.create")
        } />,
        <Card>
            <TabbedForm {...props}
                toolbar={
                    <RoleToolbar 
                        {...props} 
                        isEditRole={isEditRole} 
                        savingRole={saving}
                        isInvalid={isInvalid}
                        onSave={onSave}
                    />
                }
            >
                <FormTab label="resources.roles.tabs.role">
                    <FormControl variant="outlined" 
                        style={{width: '35%', margin:'15px 0px'}} 
                        error={isInvalid}
                    >
                        <InputLabel 
                            htmlFor="component-outlined"
                        >
                            {translate("resources.roles.fields.name")}
                        </InputLabel>
                        <OutlinedInput 
                            id="component-outlined" 
                            value={role?.name} 
                            onChange={(e) => onNameChange(e.target.value)} 
                            label={translate("resources.roles.fields.name")}
                        />
                        <FormHelperText 
                            id="component-error-text"
                        >
                            {translate(messageInvalid)}
                        </FormHelperText>
                    </FormControl>
                </FormTab>
                <FormTab label="resources.roles.tabs.permissions">
                    <RolePermissions
                        permissions={permissions}
                        role={role}
                        onPermissionsChange={onPermissionsChange}
                    />
                </FormTab>
                <FormTab label="resources.roles.tabs.users">
                    <RoleUsers
                        {...props} 
                        userList={users} 
                        role={role}
                        onUsersChange={onUsersChange}
                    />
                </FormTab>
            </TabbedForm>
        </Card>
    ];
};
