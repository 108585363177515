import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import React, {useState, useRef} from "react";
import {BooleanInput, Button, CheckboxGroupInput, FormDataConsumer, FormTab, useTranslate,} from "react-admin";

export const ELoginOptions = {
  LOGIN_BY_GOOGLE: "LOGIN_BY_GOOGLE",
  _loginManual: "_loginManual",
  LOGIN_BY_QR_CODE: "LOGIN_BY_QR_CODE",
  LOGIN_MANUAL: "LOGIN_MANUAL",
};

export const LoginManualOptions = [
  {
    id: "phone",
    name: "resources.settings.fields.login_phone",
  },
  {
    id: "email",
    name: "resources.settings.fields.login_email",
  },
]
export const LoginSettings = props => {
  const [optionLogin, setOptionLogin] = useState('');
  const [settings, setSettings] = useState(null);
  const loginManual = useRef();
  const translate = useTranslate();
  const handleDialogAction = () => {
    switch (optionLogin) {
      case ELoginOptions.LOGIN_BY_GOOGLE:
        settings.LOGIN_BY_GOOGLE = true;
        break;
      case ELoginOptions._loginManual:
        if(!settings.LOGIN_MANUAL.length){
          settings.LOGIN_MANUAL = ['email', 'phone'];
        }
        settings._loginManual = true;
        break;
      default:
        break;
    }
    setOptionLogin('');
  };

  const onChangeLoginByGoogle = (value) => {
    if(!value && !settings._loginManual){
      setOptionLogin(ELoginOptions.LOGIN_BY_GOOGLE);
    }
  }

  const onChangeLoginByManual = (value) => {
    if(value && settings.LOGIN_BY_GOOGLE){
      settings.LOGIN_MANUAL = ['email', 'phone'];
    }else if(!value && settings.LOGIN_BY_GOOGLE){
      settings.LOGIN_MANUAL = [];
    }else if(!value && !settings.LOGIN_BY_GOOGLE){
      setOptionLogin(ELoginOptions._loginManual);
    }
  }

  const onChangeLoginManualOptions = (value) => {
    if(!value.length && (!settings.LOGIN_BY_GOOGLE || settings.LOGIN_BY_GOOGLE)){
      settings._loginManual = false;
      setOptionLogin(ELoginOptions._loginManual);
    }
  }

  return (
    <FormTab
      {...props}
      label="resources.settings.tabs.login"
      icon={<VerifiedUser />}
    >
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          setSettings(formData);
          return (
              <div>
                <BooleanInput
                    source="LOGIN_BY_GOOGLE"
                    label="resources.settings.fields.sso_google"
                    fullWidth
                    onChange={onChangeLoginByGoogle}
                />
                <BooleanInput
                      ref={loginManual}
                      source="_loginManual"
                      label="resources.settings.fields.login_manual"
                      fullWidth
                      onChange={onChangeLoginByManual}
                      initialValue={!!formData.LOGIN_MANUAL.length}
                  />
                { formData._loginManual &&
                  (<CheckboxGroupInput
                    style={{marginLeft: 30}}
                    row={false}
                    source="LOGIN_MANUAL"
                    label="resources.settings.fields.login_manual"
                    choices={LoginManualOptions}
                    {...rest}
                    onChange={onChangeLoginManualOptions}
                  />)
                }
                <BooleanInput
                    source="LOGIN_BY_QR_CODE"
                    label="resources.settings.fields.login_qr_code"
                    fullWidth
                />
                <Dialog onClose={handleDialogAction} aria-labelledby="simple-dialog-title" open={optionLogin !== ''}>
                  <DialogTitle id="alert-dialog-title">{translate('resources.settings.validations.required_method_login_title')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {translate('resources.settings.validations.required_method_login_content')}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogAction} label="Ok"></Button>
                  </DialogActions>
                </Dialog>
              </div>
          )
        }}
      </FormDataConsumer>
    </FormTab>
  );
};
