import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";


const UserInfoLogin = (props) => {
  const classes = useStyles({ theme: props.theme });

  return (
    <div className={classes.infoBox} onClick={props.onBackToLogin}>
      <SupervisedUserCircleIcon/>
      <div>
        {props.userInfo.displayname || props.userInfo.username}
      </div>
      <ArrowBackIcon/>
    </div>
  );
};

UserInfoLogin.propTypes = {
  theme: PropTypes.object,
  onBackToLogin: PropTypes.func,
  userInfo: PropTypes.object,
};

export default UserInfoLogin;

const useStyles = makeStyles(theme => ({
  infoBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1em",
    padding: ".3em 1em .3em 1em",
    boxShadow: '1px 2px 1px #bababa',
    borderRadius: "20px",
    backgroundColor: "#eee",
    cursor: "pointer"
  }
}));
