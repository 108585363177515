import React, { useEffect } from "react";
import {
  TextInput,
  FormTab,
  BooleanInput,
  required,
  number,
  regex,
} from "react-admin";
import { Grid, Box } from "@mui/material";
import SpeedIcon from '@material-ui/icons/Speed';
import CircularProgress from "@material-ui/core/CircularProgress";

const BotUsage = (props) => {

  useEffect(() => {
  }, [props.bot]);

  return (
    <FormTab {...props} label="resources.bots.tabs.usage" icon={<SpeedIcon />}>
      {!props.bot?.id ? (
        <div
          style={{
            margin: "75px auto 100px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={50} thickness={2} />
        </div>
      ) : (
        <Grid
          container
          width="100%"
          spacing={2}
          display="flex"
          justifyContent="space-between"
        >
          <Grid item xs={8} md={6} lg={5}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextInput
                source="configs.limit_direct_messages"
                variant="outlined"
                type="number"
                label="resources.bots.fields.limit_direct_messages"
                validate={[
                  required(),
                  number(),
                  regex(/^[1-9]\d*$/, "resources.common.validations.regex_positive_integer"),
                ]}
              />
              <BooleanInput
                source="configs.no_error_over_limit"
                label="resources.bots.fields.no_error_over_limit"
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </FormTab>
  );
};

export default BotUsage;
