import React, { useState } from "react";
import {
  fetchUtils,
  FormDataConsumer,
  Notification,
  useNotify,
  useLocale,
  useSetLocale,
  useTranslate,
  PasswordInput,
  TextInput, required, minLength,
} from "react-admin";
import { Form, useForm } from "react-final-form";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import { env } from "../../env";
import {Steps} from "../../enum/ELoginFlow";
import adminProvider from "../../synapse/adminProvider";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 1em)",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "url(./images/floating-cogs.svg)",
    backgroundColor: "#f9f9f9",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: "20em",
    marginTop: "6em",
    marginBottom: "6em",
    width: '25%',
  },
  avatar: {
    paddingTop: "2em",
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  serverVersion: {
    color: "#9e9e9e",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    marginBottom: "1em",
    marginLeft: "0.5em",
  },
}));

const LoginStep = (props) => {
  const classes = useStyles({ theme: props.theme });

  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [supportPassAuth, setSupportPassAuth] = useState(true);
  var locale = useLocale();
  const setLocale = useSetLocale();
  const translate = useTranslate();
  const base_url = localStorage.getItem("base_url");
  const cfg_base_url = env.REACT_APP_SERVER;
  const brand = env.REACT_APP_BRAND;
  // eslint-disable-next-line
  const localeChange = (locale) => {
    localStorage.setItem("locale", locale);
    setLocale(locale);
  };

  const renderInput = ({
                         meta: { touched, error } = {},
                         input: { ...inputProps },
                         ...props
                       }) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      fullWidth
    />
  );

  const validate = values => {
    const errors = {};
    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    if (!values.base_url) {
      errors.base_url = translate("ra.validation.required");
    } else {
      if (!values.base_url.match(/^(http|https):\/\//)) {
        errors.base_url = translate("synapseadmin.auth.protocol_error");
      } else if (
        !values.base_url.match(
          /^(http|https):\/\/[a-zA-Z0-9\-.]+(:\d{1,5})?[^?&\s]*$/
        )
      ) {
        errors.base_url = translate("synapseadmin.auth.url_error");
      }
    }
    return errors;
  };

  const handleSubmit = async (auth) => {
    try {
      setLoading(true);
      const dto = {
        step: Steps.LOGIN,
        user: auth.username,
        password: auth.password
      }
      const res = await adminProvider.loginStep(dto);
      const result = res?.json;
      props.onUpdateAccount({
        username: auth.username,
        password: auth.password,
        displayname: result?.displayname
      });
      props.handleSubmit(res?.json);
    } catch (error) {
      if (error.body?.errcode === 'M_USER_DEACTIVATED') {
        notify("synapseadmin.auth.login_flow.user_deactivated", "error");
        return;
      }
      notify(
        typeof error === "string"
          ? error
          : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
        "warning"
      );
    }
    finally {
      setLoading(false);
    }
  };

  const extractHomeServer = username => {
    const usernameRegex = /@[a-zA-Z0-9._=\-/]+:([a-zA-Z0-9\-.]+\.[a-zA-Z]+)/;
    if (!username) return null;
    const res = username.match(usernameRegex);
    if (res) return res[1];
    return null;
  };

  const UserData = ({ formData }) => {
    const form = useForm();

    const handleUsernameChange = _ => {
      if (formData.base_url || cfg_base_url) return;
      // check if username is a full qualified userId then set base_url accordially
      const home_server = extractHomeServer(formData.username);
      const wellKnownUrl = `https://${home_server}/.well-known/matrix/client`;
      if (home_server) {
        // fetch .well-known entry to get base_url
        fetchUtils
          .fetchJson(wellKnownUrl, { method: "GET" })
          .then(({ json }) => {
            form.change("base_url", json["m.homeserver"].base_url);
          })
          .catch(_ => {
            // if there is no .well-known entry, try the home server name
            form.change("base_url", `https://${home_server}`);
          });
      }
    };

    return (
      <div>
        <div className={classes.input}>
          <TextInput
            autoFocus
            name="username"
            component={renderInput}
            label={"resources.profile.fields.username"}
            disabled={loading || !supportPassAuth}
            onBlur={handleUsernameChange}
            resettable
            fullWidth
          />
        </div>
        <div className={classes.input}>
          <PasswordInput
            label={"resources.profile.fields.password"}
            name="password"
            source="password"
            variant="outlined"
            autoComplete="new-password"
            disabled={loading || !supportPassAuth}
            fullWidth
            validate={[
              required("resources.profile.fields.password_required"),
              minLength(6, "resources.profile.fields.minlength_password")
            ]}
          />
        </div>
      </div>
    );
  };

  return (
    <Form
      initialValues={{ base_url: cfg_base_url || base_url }}
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              <div className={classes.hint}>
                {translate("synapseadmin.auth.welcome_brand", {brand:  brand || 'Synapse'})}
              </div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Select
                    value={locale}
                    onChange={e => {
                      localeChange(e.target.value);
                    }}
                    fullWidth
                    disabled={loading}
                  >
                    {/* <MenuItem value="de">Deutsch</MenuItem> */}
                    <MenuItem value="en">English</MenuItem>
                    {/* <MenuItem value="zh">简体中文</MenuItem> */}
                    <MenuItem value="vi">Tiếng Việt</MenuItem>
                  </Select>
                </div>
                <FormDataConsumer>
                  {formDataProps => <UserData {...formDataProps} />}
                </FormDataConsumer>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading || !supportPassAuth}
                  className={classes.button}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  {translate("ra.auth.sign_in")}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

LoginStep.propTypes = {
  theme: PropTypes.object,
  handleSubmit: PropTypes.func,
  onUpdateAccount: PropTypes.object,
};

export default LoginStep;
