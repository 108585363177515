import { required, regex } from "react-admin";

export const validateDomains = [
  required(),
  regex(
    /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
    "resources.settings.validations.regex_domain"
  ),
];

export function isProduction() {
  return process.env.NODE_ENV?.toLowerCase() === "production";
}

export const formatDate = date => {
  const newData = new Date(date);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return newData.toLocaleDateString("en-US", options);
};

/**
 * Shorten string.
 * @param {string} str input string
 * @param {number} max characters
 * @param {string} condition customize the separator
 *
 * @returns {string}
 *
 * @example
 * console.log( truncate("123456789abcde") ); // 123...bcde (using built-in defaults)
 * console.log( truncate("123456789abcde", 8) ); // 12...cde (max of 8 characters)
 * console.log( truncate("123456789abcde", 12, "_") ); // 12345_9abcde (customize the separator)
 */
export function truncate(str, max, sep) {
  // Default to 10 characters
  max = max || 10;

  var len = str.length;
  if (len > max) {
    // Default to elipsis
    sep = sep || "...";

    var seplen = sep.length;

    // If seperator is larger than character limit,
    // well then we don't want to just show the seperator,
    // so just show right hand side of the string.
    if (seplen > max) {
      return str.substr(len - max);
    }

    // Half the difference between max and string length.
    // Multiply negative because small minus big.
    // Must account for length of separator too.
    var n = -0.5 * (max - len - seplen);

    // This gives us the centerline.
    var center = len / 2;

    var front = str.substr(0, center - n);
    var back = str.substr(len - center + n); // without second arg, will automatically go to end of line.

    return front + sep + back;
  }

  return str;
}

/**
 * Check valid file type.
 * @param {string} fileExtension file extension
 * @param {Array.<string>} allowedExtension list allow extension
 *
 * @returns {boolean}
 *
 * @example
 * console.log( validateFileType('image/jpg', ["png", "jpg", "jpeg"]) ); // true
 */
export function validateFileType(fileExtension, allowedExtension) {
  var isValid = false;

  for (var index in allowedExtension) {
    if (fileExtension.includes(allowedExtension[index])) {
      isValid = true;
      break;
    }
  }

  return isValid;
}

export function isMobile() {
  const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
  const userAgent = navigator.userAgent.toLowerCase();
  return mobileRegex.test(userAgent);
}
