import React, { useEffect } from "react";
import { FormTab, useTranslate } from "react-admin";
import HelpIcon from "@material-ui/icons/Help";
import CircularProgress from "@material-ui/core/CircularProgress";
import HowToUseBotToken from "./HowToUseBotToken";
import SyntaxFormatMessage from "./BotHelp/SyntaxFormatMessage";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { EBotHelp } from "../../../enum/EBotHelp";

const BotHelp = props => {
  const translate = useTranslate();
  useEffect(() => {}, [props.bot]);

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: "2px solid #c8c8c8",
  }));

  return (
    <FormTab {...props} label="resources.bots.tabs.help" icon={<HelpIcon />}>
      {!props?.botToken ? (
        <div
          style={{
            margin: "75px auto 100px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={50} thickness={2} />
        </div>
      ) : (
        <>
          <h5>{translate("resources.bots.fields.bot_broadcast_message")}</h5>
          <AccordionDetails>
            <HowToUseBotToken
              botToken={props.botToken}
              type={EBotHelp.M_BROADCAST_MESSAGE}
            />
          </AccordionDetails>
          <h5>{translate("resources.bots.fields.bot_direct_message")}</h5>
          <AccordionDetails>
            <HowToUseBotToken
              botToken={props.botToken}
              type={EBotHelp.M_DIRECT_MESSAGE}
            />
          </AccordionDetails>
          <h5>{translate("resources.bots.fields.bot_request_status")}</h5>
          <AccordionDetails>
            <HowToUseBotToken
              type={EBotHelp.M_REQUEST_STATUS}
            />
          </AccordionDetails>
          <h5>{translate("resources.bots.fields.bot_format_message")}</h5>
          <SyntaxFormatMessage />
        </>
      )}
    </FormTab>
  );
};

export default BotHelp;
