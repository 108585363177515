export const Steps = {
    LOGIN: 'login',
    REQUEST_OTP: 'request_otp',
    VERIFY_OTP: 'verify_otp',
    SETUP_TWO_FACTOR: 'setup_two_factor',
    VERIFY_TWO_FACTOR: 'verify_two_factor',
    BACKUP_KEYS: 'backup_keys',
    RESTORE_KEYS: 'restore_keys',
    VERIFY_KEYS: 'verify_keys',
    VERIFY_KEYS_DONE: 'done',
    RESET_KEYS: 'reset_keys',
    UPDATE_PASSWORD: 'update_password',
    VERIFY_RESET_KEYS: 'verify_reset_keys'
}
