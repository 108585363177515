import React from "react";
import {
  FormDataConsumer,
  FormTab,
  TextInput,
  number,
  required,
  regex,
} from "react-admin";
import BotIcon from "../../customIcons/BotIcon";
import { Grid } from "@mui/material";

export const EBotSetting = {
  TTL_KEY_SHARE_BOT: "TTL_KEY_SHARE_BOT",
};

export const BotSettings = props => {
  return (
    <FormTab
      {...props}
      label="resources.settings.tabs.bot"
      icon={<BotIcon />}
    >
      <FormDataConsumer>
        {({ formData, ..._ }) => {
          return <Grid container width="100%" spacing={2}>
            <Grid item xs={8} md={6} lg={4}>
              <TextInput
                source={EBotSetting.TTL_KEY_SHARE_BOT}
                label="resources.settings.fields.ttl_bot_share"
                variant="outlined"
                type="number"
                fullWidth
                validate={[
                  required(),
                  number(),
                  regex(/^[1-9]\d{0,5}$/, "resources.settings.validations.regex_integer"),
                ]}
              />
            </Grid>
          </Grid>;
        }}
      </FormDataConsumer>
    </FormTab>
  );
};
