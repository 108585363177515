import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  TextField,
  Loading,
} from "react-admin";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { EPermission } from "../../enum/EPermission";
import { hasPermission } from "../../helper/permission";
import { exporterUser } from "../../helper/users";
import React from "react";
import { AvatarField, date_format, UserPagination, useStyles } from "../users";
import UserFilter from "./user-filter";
import { UserListActions } from "./user-list-actions";
import { UserBulkActionButtons } from "./user-bulk-action-buttons";

const UserList = props => {
  return (
    <List
      {...props}
      filters={<UserFilter />}
      filterDefaultValues={{
        deactivated: false,
        two_factor: undefined,
      }}
      sort={{ field: "creation_ts", order: "DESC" }}
      actions={<UserListActions maxResults={10000} exporter={exporterUser} />}
      bulkActionButtons={
        hasPermission(
          [ 
            EPermission.SUPER_ADMIN
          ],
          props.permissions.permissions,
        ) && <UserBulkActionButtons />
      }
      pagination={<UserPagination />}
    >
      <UserGrid {...props} />
    </List>
  );
};

const UserGrid = props => {
  const classes = useStyles();
  if (props.loading) {
      return <Loading />
  }
  return (
    <Datagrid
        hasBulkActions={true}
        rowClick={ 
          hasPermission(
            [ 
              EPermission.USERS_DETAILS
            ],
            props.permissions.permissions
          ) && "edit"
        }
      >
        <AvatarField
          source="avatar_src"
          className={classes.small}
          sortBy="avatar_url"
        />
        <TextField source="id" sortBy="name" />
        <TextField source="displayname" style={{ lineBreak: "anywhere", maxWidth: 320 }} />
        {hasPermission([EPermission.SUPER_ADMIN], props.permissions.permissions) && <BooleanField source="admin" />}
        <BooleanField source="deactivated" />
        <BooleanField source="two_factor" label="two_factor.name" sortable={false} />
        <DateField
          source="creation_ts"
          label="resources.users.fields.creation_ts_ms"
          showTime
          options={date_format}
        />
      </Datagrid>
  );
};

UserList.propTypes = {
  permissions: PropTypes.object,
}

const mapStateToProps = function (state) {
  return {
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps)(UserList);
