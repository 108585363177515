import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import DevicesIcon from "@material-ui/icons/Devices";
import Security from "@material-ui/icons/Security";
import VpnKey from "@material-ui/icons/VpnKey";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/CheckCircle";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import ViewListIcon from "@material-ui/icons/ViewList";
import { withStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Autorenew from "@material-ui/icons/Autorenew";
import Tooltip from '@mui/material/Tooltip';

import React,
{
  useEffect,
  useState,
  useCallback
}
  from "react";
import {
  ArrayField,
  BooleanInput,
  Button,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  email,
  FormTab,
  maxLength,
  minLength,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  regex,
  required,
  SaveButton,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  useDataProvider,
  useTranslate,
  useNotify,
  FormDataConsumer,
  useRefresh,
  PasswordInput,
  SelectInput,
  Loading,
  useMutation,
  useRedirect,
} from "react-admin";
import userProvider from "../synapse/userProvider";
import { ProtectMediaButton, QuarantineMediaButton } from "./media";
import { ServerNoticeButton } from "./ServerNotices";
import { logger } from '../utils/logger';
import { useForm } from "react-final-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { shortenId } from "../helper/users"
import roleProvider from "../synapse/roleProvider";
import PermissionWrapper from "./common/PermissionWrapper";
import { EPermission } from "../enum/EPermission";
import { hasPermission } from "../helper/permission";
import { Error as ErrorComponent } from "./Error";
import { getCurrentUserId } from '../utils/storage';

function mapStateToProps(state) {
  return {
    permissions: state.permissions.permissions,
  };
}

const TextFieldInForm = ({ variant, ...props }) => <TextField {...props} />;
TextFieldInForm.defaultProps = TextField.defaultProps;

export const useStyles = makeStyles({
  small: {
    height: "40px",
    width: "40px",
  },
  large: {
    height: "120px",
    width: "120px",
    float: "right",
  },
  boxMarginTop: {
    marginTop: '20px',
  },
  inputWidth: {
    width: '256px',
  },
  copyClipboardBox: {
    display: 'flex'
  },
  copyClipboard: {
    cursor: 'pointer',
    height: '30px',
    margin: '0 10px'
  },
  randomPassword: {
    cursor: 'pointer',
    height: '30px',
  },
  menuPaper: {
    maxHeight: '200px',
  },
  passwordActionBox: {
    height: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export const date_format = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

export const UserPagination = props => (
  <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />
);

export const AvatarField = ({ source, className, record = {} }) => (
  <Avatar src={record[source]} className={className} />
);

// https://matrix.org/docs/spec/appendices#user-identifiers
// here only local part of user_id
// maxLength = 255 - "@" - ":" - localStorage.getItem("home_server").length
// localStorage.getItem("home_server").length is not valid here
// eslint-disable-next-line
const validateUser = [
  required(),
  maxLength(253),
  regex(/^[a-z0-9._=\-/]+$/, "synapseadmin.users.invalid_user_id"),
];


const validatePhoneNumber = (dataProvider, translate) => {
  return [
    regex(/^[0-9._=\-/]+$/, "synapseadmin.users.invalid_phone_number"),
    minLength(11),
  ]
}

const validatePhoneNumberToUpdate = (dataProvider, translate) => {
  return [
    regex(/^[0-9._=\-/]+$/, "synapseadmin.users.invalid_phone_number"),
    minLength(11),
  ]
}

const validateEmail = (dataProvider, translate) => {
  return [
    function (value, form) {
      return !value ? 'Required' : undefined;
    },
    email(),
    checkEmailValid(dataProvider, translate),
  ]
}

const validateEmailEdit = (dataProvider, translate, formData) => {
  return [
    function (value, form) {
      return !value ? 'Required' : undefined;
    },
    email(),
    checkEmailValid(dataProvider, translate),
  ]
}

const checkEmailValid = (dataProvider, translate) => async (value, values) => {
  if(value){
    const emailParts = value.split('@');
    return !(/^[a-z0-9._=\-/]+$/.test(emailParts[0])) ? translate("resources.users.invalid_email", { smart_count: 1 }) : '';
  }
};

const validateDisplayname = (translate) => {
  return [
    function (value) {
      return !value ? 'Required' : undefined;
    },
    checkDisplaynameValid(translate),
    maxLength(64)
  ]
}

const checkDisplaynameValid = (translate) => async (value) => {
  if(value){
    return (/^\s*$/.test(value.trim())) ? translate("resources.users.invalid_displayname") : '';
  }
};

const validateUsernameToUpdate = (dataProvider, translate) => {
  // noinspection RegExpDuplicateCharacterInClass
  return [
    regex(
      /^.{5,}$/,
      "resources.users.invalid_short_usename"
    ),
    regex(
      /^.{1,32}$/,
      "resources.users.invalid_length_usename"
    ),
    regex(
      /^[a-zA-Z0-9_]*$/,
      "resources.users.invalid_char_usename"
    ),
    regex(
      /^(?!.*__)(?!^([kK][tT][aA][lL][kK]|[aA][dD][mM][iI][nN]|[sS][uU][pP][pP][oO][rR][tT]))[(a-zA-Z)(A-Za-z)][(a-zA-Z0-9_)(A-Za-z0-9_)]*[(a-zA-Z0-9)(A-Za-z0-9)]$|^$/,
      "resources.users.invalid_usename"
    ),
  ]
}

// eslint-disable-next-line
const validateAddress = [required(), maxLength(255)];

export function generateRandomUser() {
  const homeserver = localStorage.getItem("home_server");
  const user_id =
    "@" +
    Array(8)
      .fill("0123456789abcdefghijklmnopqrstuvwxyz")
      .map(
        x =>
          x[
            Math.floor(
              (crypto.getRandomValues(new Uint32Array(1))[0] /
                (0xffffffff + 1)) *
                x.length
            )
          ]
      )
      .join("") +
    ":" +
    homeserver;

  const password = Array(20)
    .fill(
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$"
    )
    .map(
      x =>
        x[
          Math.floor(
            (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
              x.length
          )
        ]
    )
    .join("");

  return {
    id: user_id,
    password: password,
  };
}

const VerticalLine = (props) => {
  return (
    <div
      style={
        {
          borderLeft: '1px solid #ccc',
          margin: '0 5px 0 5px',
          paddingLeft: '5px',
          height: '35px',
        }
      }
    >
      { props?.children && React.cloneElement(props.children, { ...props }) }
    </div>
  );
};

const VerticalLineButton = (props) => {
  return (
      <div
          style={
            {
              borderLeft: '1px solid #ccc',
              margin: '0 5px 0 5px',
              paddingLeft: '5px',
              height: '35px',
            }
          }
      >
        { props.children }
      </div>
  );
};


const FieldLabel = ({ children }) => {
  return (
    <div
      style={{
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0',
        fontSize: '12px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1',
        letterSpacing: '0.00938em',
        marginTop: '0px',
      }}
    >
      { children }
    </div>
  );
}

const TextFieldCs = (props) => {
  const deactivated = props.deactivated;
  const earsed = props.earsed;
  return (
    <div
      style={{
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.5',
        letterSpacing: '0.00938em',
        padding: '8px 0 4px',
        color: deactivated || earsed ? 'rgb(244, 67, 54)' : '#2cbf52',
      }}
    >
      { props.children }
    </div>
  );
}




const UserEditToolbar = props => {
  const [mutate] = useMutation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenReset2FA, setIsOpenReset2FA] = React.useState(false);
  const [isOpenDeactivated, setIsOpenDeactivated] = React.useState(false);
  const [displayName, setDisplayName] = React.useState('');
  const [securityStatus, setSecurityStatus] = React.useState({});
  const [loading, setLoading] = useState(false);
  const deactivated = props.record.deactivated;
  const translate = useTranslate();
  const notify = useNotify();
  const form = useForm();
  if (
    !hasPermission([EPermission.SUPER_ADMIN], props.permissions)
    || form.getState().values?.super_admin
  ) {
    form.change("admin", null);
  }
  const refresh = useRefresh();
  const deactivatedTranslate = deactivated ? 'activate' : 'deactivated';
  const [isInvalid, setIsInvalid] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');

  useEffect(() => {
    userProvider.getSecurityStatus(props.record.id).then(result => {
      setSecurityStatus(result.json);
    }).catch(error => {
      logger.error(error.message);
    });
    if (props.record?.username) {
      setDisplayName(props.record.username);
    } else {
      setDisplayName(shortenId(props.record.id));
    }
  }, [props.record.id, props.record.username]);

  function onClose() {
    setIsOpen(false);
  }
  function onCloseReset() {
    setIsOpenReset2FA(false);
    setIsOpenDeactivated(false);
  }
  function onConfirmReset2FA() {
    setIsOpenReset2FA(true);
  }
  function onConfirmDeactivated() {
    setIsOpenDeactivated(true);
  }

  function onCloseConfirm2FA() {
    setLoading(true);
    userProvider.remove2FA(props.record.id)
      .then(() => {
        notify("resources.users.reset_2fa_success");
        setIsOpenReset2FA(false);
      })
      .catch(error => {
        logger.error(error.message);
        if (error && error.body && error.body.errcode === 'M_NOT_ENABLE_TWO_FACTOR') {
          notify(
              "resources.users.not_enable_2fa",
              { type: "error" },
          );
          return;
        }
        notify(
            "resources.users.reset_2fa_failed",
            { type: "error" },
        );
      })
      .finally(() => {
        setIsOpenReset2FA(false);
        refresh();
        setLoading(false);
      });
  }

  function onDeactivatedUser() {
    setLoading(true);
    const dto = {
      deactivated: !deactivated,
    };
    deactivated && (dto.displayname = displayName?.trim());
    userProvider.deactivatedUser(props.record.id, dto)
      .then(() => {
        notify(`resources.users.${deactivatedTranslate}_success`);
        window.location = '/#/users'
      })
      .catch(error => {
        logger.error(error.message);
        notify(
            `resources.users.${deactivatedTranslate}_failed`,
            { type: "error" },
        );
      }).finally(() => {
        setIsOpenDeactivated(false);
      });
  }

  const recordWillUpdate = form.getState().values;
  if(props.record.email !== recordWillUpdate.email){
    recordWillUpdate.external_ids = [];
  }

  const onDisplayNameChange = (value) => {
    const valueInput = value;
    setDisplayName(valueInput)
    setIsInvalid(checkDisplaynameValid(valueInput));
    setMessageError(handleMessageErr(valueInput));
  }

  const checkDisplaynameValid = (value) => {
    return (!value.length || value.length > 64 || (/^\s*$/.test(value?.trim())));
  }

  const handleMessageErr = (value) => {
    if (!value.length) {
      return 'resources.users.required_displayname';
    }
    if (value.length > 64) {
      return 'resources.users.invalid_length_displayname';
    }
    if (/^\s*$/.test(value?.trim())) {
      return 'resources.users.invalid_displayname';
    }
  }

  const save = useCallback(
    async (values) => {
        try {
            await mutate({
                type: 'update',
                resource: 'users',
                payload: { data: values },
            }, {
              returnPromise: true,
              onSuccess: () => {
                form.change("password", null);
                notify("resources.users.update_success", { type: "success" });
              }
            });
        } catch (e) {
            if (e.body?.statusCode === 400 && e.body?.errors) {
              notify("resources.users.invalid_form", { type: "error" });
              return e.body.errors;
            } else if (e.body?.errcode === 'M_USER_DATA_OUT_OF_DATE') {
              notify("resources.users.out_of_date", { type: "error" });
            } else {
              notify("resources.users.save_error", { type: "error" });
            }
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutate],
  );

  return (
    <Toolbar {...props}>
      {
        (!props.record?.deactivated && hasPermission([EPermission.USERS_UPDATE], props.permissions)) &&
        <SaveButton
          submitOnEnter={true}
          disabled={props.isHasPassword ? false : (props.pristine)}
          onSave={save}
        />
      }
      {
        (!props.record?.deactivated && hasPermission([EPermission.SUPER_ADMIN], props.permissions)) &&
        <VerticalLine>
          <ServerNoticeButton />
        </VerticalLine>
      }
      {
        (!props.record?.erased && hasPermission([EPermission.USERS_ACTIVE], props.permissions)) &&
        <VerticalLineButton>
          <Button
            label={`resources.users.${deactivatedTranslate}`}
            style={{color: deactivated ? '' : '#f44336'}}
            onClick={onConfirmDeactivated}
          >
            {
              deactivated ?
              <LockOpenIcon style={{ fontSize: "20" }} /> :
              <LockIcon style={{ fontSize: "20" }} />
            }
          </Button>
        </VerticalLineButton>
      }
      {
        !props.record?.deactivated &&
        <>
          {
             hasPermission([EPermission.USERS_RESET_TWO_FACTOR], props.permissions) &&
             <VerticalLineButton>
              <Button
                  label="resources.users.reset_2fa_label"
                  style={{ color: !securityStatus.twoFactor ? '' : '#f44336' }}
                  onClick={onConfirmReset2FA}
                  disabled={!securityStatus.twoFactor}
              >
                <Security style={{ fontSize: "20" }} />
              </Button>
            </VerticalLineButton>
          }
        </>
      }
      {
        (!props.record?.erased && hasPermission([EPermission.USERS_ERASE], props.permissions)) &&
        <VerticalLine>
          <DeleteButton
            label="resources.users.action.delete_account"
            confirmTitle={translate("resources.users.helper.erase", {
              smart_count: 1,
            })}
            confirmContent={translate("resources.users.helper.delete_account")}
            mutationMode="pessimistic"
            style={{ marginBottom: '4px'}}
          />
        </VerticalLine>
      }
      {/* Dialog alert email exist */}
      <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={isOpen}>
        <DialogTitle id="alert-dialog-title">{translate('resources.users.email_already_exists')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate('resources.users.email_already_exists_content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} label="Close"></Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Reset Two Factor */}
      <Dialog onClose={onCloseReset} aria-labelledby="reset-dialog-title" open={isOpenReset2FA}>
        <DialogTitle id="reset-dialog-title">{translate('resources.settings.validations.reset_2fa_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="reset-dialog-description">
            {translate('resources.settings.validations.reset_2fa_content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseReset} color="inherit" label="Cancel">
            <CancelIcon />
          </Button>
          {
            loading ?
            <CircularProgress
              style={{ height: "20px", width: "20px" }}
              color="primary"
            /> :
            <Button onClick={onCloseConfirm2FA} color="primary" label="Confirm">
              <CheckIcon />
            </Button>
          }

        </DialogActions>
      </Dialog>

      {/* Dialog Deactivated */}
      <Dialog onClose={onCloseReset} aria-labelledby="reset-dialog-title" open={isOpenDeactivated}>
        <DialogTitle id="reset-dialog-title">{translate(`resources.settings.validations.${deactivatedTranslate}_title`)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="reset-dialog-description">
            {translate(`resources.settings.validations.${deactivatedTranslate}_content`)}
          </DialogContentText>
          {
            deactivated &&
            <FormControl variant="outlined"
              style={{width: '100%', marginTop: '10px'}}
              error={isInvalid}
            >
              <InputLabel htmlFor="component-outlined">Display Name</InputLabel>
              <StyledOutlinedInput
                id="component-outlined"
                value={displayName}
                onChange={(e) => onDisplayNameChange(e.target.value)}
                label="Display Name"
              />
              <FormHelperText id="component-error-text">{translate(messageError)}</FormHelperText>
            </FormControl>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseReset} color="inherit" label="Cancel">
            <CancelIcon />
          </Button>
          {
            loading ?
            <CircularProgress
                style={{ height: "20px", width: "20px" }}
                color="primary"
            /> :
            <Button
              onClick={onDeactivatedUser}
              color="primary"
              label="Confirm"
              disabled={isInvalid && deactivated}
            >
              <CheckIcon />
            </Button>
          }
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
};

const passwordValidation = (value, _) =>  {
  if ((/^\s*$/.test(value?.trim())) || (/\s/.test(value))) {
      return 'resources.users.invalid_password';
  }
  return undefined;
}

export const UserCreateConnect = props => {
  const notify = useNotify();
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles();

  const [isFetchRoles, setIsFetchRoles] = useState(false);
  const [listRoles, setListRoles] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const [copyText, setCopyText] = useState('Copied');

  useEffect(() => {
    // Fetch list roles for Autocomplete input
    if (hasPermission([EPermission.SUPER_ADMIN], props.permissions)) {
      setIsFetchRoles(true);
      roleProvider.getListRoles().then((roles) => {
        setListRoles(roles);
        setIsFetchRoles(false);
      }).catch(error => {
        setIsFetchRoles(false);
      });
    }
  }, []);

  function onRandomPassword(event, formData)  {
    event.preventDefault();
    formData.password = Math.random().toString(36).slice(-8);
    document.getElementById("password").focus();
  }

  const onCopy = (event, formData) => {
    event.preventDefault();
    setCopyText(formData.password ? translate("resources.users.action.copied") : translate("resources.users.action.empty"));
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000)
  }

  const save = useCallback(
    async (values) => {
      try {
        await mutate({
          type: 'create',
          resource: 'users',
          payload: { data: values },
        }, {
          returnPromise: true,
          onSuccess: (data) => {
            redirect('edit', '/users', data['data']['id']);
            notify("resources.users.create_success", { type: "success" });
          }
        });
      } catch (e) {
        if (e.body?.statusCode === 400 && e.body?.errors) {
          notify("resources.users.invalid_form", { type: "error" });
          return e.body.errors;
        } else {
          notify("resources.users.save_error", { type: "error" });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutate, redirect]
  );

  return (
    <Create {...props}>
      <SimpleForm save={save}>
        {/*<TextInput source="id" autoComplete="off" validate={validateUser} />*/}
        <TextInput
          source="email"
          autoComplete="off"
          variant="outlined"
          validate={validateEmail(dataProvider, translate)}
        />
        <TextInput
          source="phonenumber"
          autoComplete="off"
          label="resources.users.fields.phoneNumber"
          helperText={false}
          variant="outlined"
          validate={validatePhoneNumber(dataProvider, translate)}
        />
        <TextField
          source={translate(
            "resources.users.helper.phone_include_country_code",
            {
              phoneNumber: '84949196969'
            }
          )}
        />
        <TextInput
          source="displayname"
          label="resources.users.fields.displayname"
          variant="outlined"
          validate={validateDisplayname(translate)}
        />
        {/*<PasswordInput
          source="password"
          variant="outlined"
          autoComplete="new-password"
          validate={maxLength(512)}
        />*/}
        <PermissionWrapper names={[ EPermission.SUPER_ADMIN ]}>
          <BooleanInput source="admin" label="resources.users.fields.sub_admin"/>
          <FormDataConsumer>
            {
              ({ formData }) => formData.admin && (
                <>
                  <div className={classes.copyClipboardBox}>
                    <PasswordInput
                      id="password"
                      className={classes.inputWidth}
                      source="password"
                      autoComplete="new-password"
                      variant="outlined"
                      validate={[
                        passwordValidation,
                        minLength(6, "resources.profile.fields.minlength_password")
                      ]}
                    />
                    <div className={classes.passwordActionBox}>
                      <CopyToClipboard text={formData.password}>
                        <span className={classes.copyClipboard} onClick={(event) => onCopy(event, formData)}>
                          <Tooltip title={copyText} open={isCopied} placement="top">
                            <FileCopyIcon style={{fontSize: "23"}}/>
                          </Tooltip>
                        </span>
                      </CopyToClipboard>
                      <span className={classes.randomPassword} onClick={(event) => onRandomPassword(event, formData)}>
                        <Tooltip title={translate("resources.users.action.generate")} placement="top">
                          <Autorenew style={{fontSize: "25"}}/>
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                  <br/>
                  <SelectInput
                    variant="outlined"
                    source="role_id"
                    choices={listRoles}
                    optionText="name"
                    optionValue="roleId"
                    size="medium"
                    translateChoice={false}
                    className={classes.inputWidth}
                    loading={isFetchRoles}
                    label="resources.users.fields.role"
                    resettable={true}
                  />
                </>
              )
            }
          </FormDataConsumer>
        </PermissionWrapper>
        {/*<ArrayInput source="threepids">
          <SimpleFormIterator>
            <SelectInput
              source="medium"
              choices={[
                { id: "email", name: "resources.users.email" },
                {
                  id: "msisdn",
                  name: "resources.users.msisdn",
                  selected: true,
                },
              ]}
              validate={required()}
            />
            <TextInput
              style={{
                height: 0,
                borderWidth: 0,
              }}
              source="medium"
              defaultValue={"msisdn"}
              type={"hidden"}
              label={false}
              boder={false}
              variant="outlined"
            />
            <TextInput source="address" validate={validateAddress} variant="outlined"/>
          </SimpleFormIterator>
        </ArrayInput>*/}
        {/*<ArrayInput source="external_ids" label="synapseadmin.users.tabs.sso">
          <SimpleFormIterator>
            <TextInput source="auth_provider" validate={required()} variant="outlined" />
            <TextInput
              source="external_id"
              label="resources.users.fields.id"
              variant="outlined"
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>*/}
      </SimpleForm>
    </Create>
  )
};

export const UserCreate = connect(mapStateToProps)(UserCreateConnect);

const UserTitle = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.users.name", {
        smart_count: 1,
      })}{" "}
      {record ? `"${record.displayname}"` : ""}
    </span>
  );
};

let recordUpdate = null;
export const UserEditConnect = props => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const translate = useTranslate();
  const [inputPassword, setInputPassword] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [isErased, setIsErased] = useState(false);
  const [isDeactivated, setIsDeactivated] = useState(false);
  const [isFetchRoles, setIsFetchRoles] = useState(false);
  const [listRoles, setListRoles] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const [copyText, setCopyText] = useState('Copied');
  const [isHasPassword, setIsHasPassword] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const currentUserId = getCurrentUserId();

  useEffect(() => {
    dataProvider.getOne("users", { id: props.id }).then(result => {
      setIsErased(result?.data.erased || false);
      if (
        result?.data.erased ||
        result?.data.deactivated ||
        !hasPermission([EPermission.USERS_UPDATE], props.permissions)
      ) {
        setIsDisable(true);
      }
      setIsDeactivated(result?.data.deactivated);
      recordUpdate = result?.data;
    }).catch(error => {
      logger.error(error.message);
    }).finally(() => {
      setLoadingPage(false);
    });

    // Fetch list roles for Autocomplete input
    if (hasPermission([EPermission.SUPER_ADMIN], props.permissions)) {
      setIsFetchRoles(true);
      roleProvider.getListRoles().then((roles) => {
        setListRoles(roles);
        setIsFetchRoles(false);
      }).catch(error => {
        setIsFetchRoles(false);
      });
    }
  }, []);

  function onRandomPassword(event, formData) {
    event.preventDefault();
    formData.password = Math.random().toString(36).slice(-8);
    document.getElementById("password").focus();
    setIsHasPassword(true);
  }

  const onCopy = (event, formData) => {
    event.preventDefault();
    setCopyText(formData.password ? translate("resources.users.action.copied") : translate("resources.users.action.empty"));
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000)
  }

  const onChangePassword = (event, formData) => {
    event.preventDefault();
    setIsHasPassword(event.target.value ? true : false);
  }


  if (loadingPage) return <Loading />;

  return (
    <Edit mutationMode="pessimistic" {...props} title={<UserTitle />}>
      <TabbedForm
        syncWithLocation={false}
        toolbar={<UserEditToolbar {...props}
        isHasPassword={isHasPassword}
        permissions={props.permissions}/>}
      >
        <FormTab
          label={translate("resources.users.name", { smart_count: 1 })}
          icon={<PersonPinIcon />}
        >
          <AvatarField
            source="avatar_src"
            sortable={false}
            className={classes.large}
          />
          <TextInput source="id" variant="outlined" disabled />
          <TextInput
            source="username"
            variant="outlined"
            validate={validateUsernameToUpdate(dataProvider, translate)}
            disabled={isDisable}
          />
          <TextInput
            source="email"
            variant="outlined"
            validate={validateEmailEdit(dataProvider, translate)}
            disabled={isDisable}
          />
          <TextInput
            source="phonenumber"
            variant="outlined"
            validate={validatePhoneNumberToUpdate(dataProvider, translate)}
            disabled={isDisable}
          />
          <TextInput
            source="displayname"
            variant="outlined"
            validate={validateDisplayname(translate)}
            disabled={isDisable}
          />
          {
            hasPermission([EPermission.SUPER_ADMIN], props.permissions) ?
              <>
                {
                  (currentUserId === props.id || recordUpdate?.super_admin) ?
                    <>
                      {translate("resources.users.fields.super_admin")}
                    </>
                    :
                    <>
                      <BooleanInput
                        source="admin"
                        disabled={isDisable}
                        label="resources.users.fields.sub_admin"
                      />
                      <FormDataConsumer>
                        {
                          ({ formData }) => formData.admin && (
                            <>
                              <div className={classes.copyClipboardBox}>
                                <PasswordInput
                                  id="password"
                                  className={classes.inputWidth}
                                  source="password"
                                  autoComplete="new-password"
                                  variant="outlined"
                                  disabled={isDeactivated || isDisable}
                                  validate={[
                                    passwordValidation,
                                    minLength(6, "resources.profile.fields.minlength_password")
                                  ]}
                                  onChange={(event) => onChangePassword(event, formData)}
                                />
                                <div className={classes.passwordActionBox}>
                                  <CopyToClipboard text={formData.password}>
                                    <span className={classes.copyClipboard} onClick={(event) => onCopy(event, formData)}>
                                      <Tooltip title={copyText} open={isCopied} placement="top">
                                        <FileCopyIcon style={{ fontSize: "23" }} />
                                      </Tooltip>
                                    </span>
                                  </CopyToClipboard>
                                  <span className={classes.randomPassword} onClick={(event) => onRandomPassword(event, formData)}>
                                    <Tooltip title={translate("resources.users.action.generate")} placement="top">
                                      <Autorenew style={{ fontSize: "25" }} />
                                    </Tooltip>
                                  </span>
                                </div>
                              </div>
                              <br />
                              <SelectInput
                                variant="outlined"
                                source="role_id"
                                choices={listRoles}
                                optionText="name"
                                optionValue="roleId"
                                size="medium"
                                translateChoice={false}
                                className={classes.inputWidth}
                                loading={isFetchRoles}
                                label="resources.users.fields.role"
                                disabled={isDeactivated || isDisable}
                                resettable={true}
                              />
                            </>
                          )
                        }
                      </FormDataConsumer>
                    </>
                }
              </> : <></>
          }
          <div className={classes.boxMarginTop}>
            <FieldLabel>
              {translate("resources.users.user_status")}
            </FieldLabel>
            <TextFieldCs
              deactivated={isDeactivated}
              earsed={isErased}
            >
              {translate(`resources.users.enums.deactivated.${
                isDeactivated ?
                  (isErased ? 'erased' : 'deactivated') :
                  'activated'
              }`)}
            </TextFieldCs>
          </div>
          <FormDataConsumer>
            {({ formData, ...rest }) => inputPassword}
          </FormDataConsumer>
          <DateField source="creation_ts_ms" showTime options={date_format} />
          <TextFieldInForm source="consent_version"  variant="outlined" />
        </FormTab>
        {
          hasPermission([EPermission.USERS_DEVICES], props.permissions) &&
          <FormTab
            label={translate("resources.devices.name", { smart_count: 2 })}
            icon={<DevicesIcon />}
            path="devices"
          >
            <ReferenceManyField
              reference="devices"
              target="user_id"
              addLabel={false}
            >
              <Datagrid style={{ width: "100%" }}>
                <TextFieldInForm source="device_id"  variant="outlined" sortable={false} />
                <TextFieldInForm source="display_name"  variant="outlined" sortable={false} />
                <TextFieldInForm source="last_seen_ip"  variant="outlined" sortable={false} />
                <DateField
                  source="last_seen_ts"
                  showTime
                  options={date_format}
                  sortable={false}
                />
                {/*<DeviceRemoveButton />*/}
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        }
        {
          hasPermission([EPermission.USERS_CONNECTION], props.permissions) &&
          <FormTab
            label="resources.connections.name"
            icon={<SettingsInputComponentIcon />}
            path="connections"
          >
            <ReferenceField
              reference="connections"
              source="id"
              addLabel={false}
              link={false}
            >
              <ArrayField
                source="devices[].sessions[0].connections"
                label="resources.connections.name"
              >
                <Datagrid style={{ width: "100%" }}>
                  <TextFieldInForm source="ip"  variant="outlined" sortable={false} />
                  <DateField
                    source="last_seen"
                    showTime
                    options={date_format}
                    sortable={false}
                  />
                  <TextField
                    source="user_agent"
                    sortable={false}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Datagrid>
              </ArrayField>
            </ReferenceField>
          </FormTab>
        }
        {
          hasPermission([EPermission.USERS_MEDIA], props.permissions) &&
          <FormTab
            label={translate("resources.users_media.name", { smart_count: 2 })}
            icon={<PermMediaIcon />}
            path="media"
          >
            <ReferenceManyField
              reference="users_media"
              target="user_id"
              addLabel={false}
              pagination={<UserPagination />}
              perPage={50}
              sort={{ field: "created_ts", order: "DESC" }}
            >
              <Datagrid style={{ width: "100%" }}>
                <DateField source="created_ts" showTime options={date_format} />
                <DateField
                  source="last_access_ts"
                  showTime
                  options={date_format}
                />
                <TextFieldInForm source="media_id" variant="outlined"/>
                <NumberField source="media_length" variant="outlined"/>
                <TextFieldInForm source="media_type" variant="outlined"/>
                <TextFieldInForm source="upload_name" variant="outlined"/>
                <TextFieldInForm source="quarantined_by" variant="outlined"/>
                {
                  hasPermission([EPermission.SUPER_ADMIN], props.permissions) &&
                  <QuarantineMediaButton label="resources.quarantine_media.action.name"/>
                }
                {
                  hasPermission([EPermission.SUPER_ADMIN], props.permissions) &&
                  <ProtectMediaButton label="resources.users_media.fields.safe_from_quarantine"/>
                }
                {
                  hasPermission([EPermission.SUPER_ADMIN], props.permissions) &&
                  <DeleteButton mutationMode="pessimistic" redirect={false}/>
                }
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        }
        {
          hasPermission([EPermission.USERS_ROOMS], props.permissions) &&
          <FormTab
            label={translate("resources.rooms.name", { smart_count: 2 })}
            icon={<ViewListIcon />}
            path="rooms"
          >
            <ReferenceManyField
              reference="joined_rooms"
              target="user_id"
              addLabel={false}
            >
              <Datagrid
                style={{ width: "100%" }}
                // rowClick={(id, basePath, record) => "/rooms/" + id + "/show"}
              >
                <TextField
                  source="id"
                  sortable={false}
                  label="resources.rooms.fields.room_id"
                />
                {/* <ReferenceField
                  label="resources.rooms.fields.name"
                  source="id"
                  reference="rooms"
                  sortable={false}
                  link=""
                >
                  <TextField source="name" sortable={false} />
                </ReferenceField> */}
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        }
        {
          hasPermission([EPermission.USERS_PUSHERS], props.permissions) &&
          <FormTab
            label={translate("resources.pushers.name", { smart_count: 2 })}
            icon={<NotificationsIcon />}
            path="pushers"
          >
            <ReferenceManyField
              reference="pushers"
              target="user_id"
              addLabel={false}
            >
              <Datagrid style={{ width: "100%" }}>
                <TextField source="kind" sortable={false} />
                <TextField source="app_display_name" sortable={false} />
                <TextField source="app_id" sortable={false} />
                <TextField source="data.url" sortable={false} />
                <TextField source="device_display_name" sortable={false} />
                <TextField source="lang" sortable={false} />
                <TextField source="profile_tag" sortable={false} />
                <TextField source="pushkey" sortable={false} />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        }
      </TabbedForm>
    </Edit>
  );
};

export const UserEdit = connect(mapStateToProps)(UserEditConnect);

const StyledOutlinedInput = withStyles({
  input: {
    padding: '12px 18px 12px 14px',
  },
})(OutlinedInput);
