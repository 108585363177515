import React, { useState, useEffect } from "react";
import {
  FETCH_START, FETCH_END, Loading, TabbedForm, useNotify, Title,
} from "react-admin";
import { logger } from '../utils/logger';
import { Error as ErrorComponent } from "./Error";
import { useDispatch } from "react-redux";
import settingProvider from "../synapse/settingProvider";
import {_3pidSettings} from "./settings/_3pidSettings";
import { OTPSettings } from "./settings/OTPSettings";
import { LoginSettings } from "./settings/LoginSettings";
import { TwoFactorSettings } from "./settings/TwoFactorSettings";
import { BotSettings } from "./settings/BotSettings";
import { GeneralSettings } from "./settings/GeneralSettings";
import Card from '@material-ui/core/Card';

export const Settings = props => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();

  useEffect(() => {
    dispatch({ type: FETCH_START });
    settingProvider.getSettings()
      .then((settings) => {
        setSettings(settings);
      }).catch(error => {
      notify(error.message, { type: "error" });
      setError(error);
    }).finally(() => {
      setLoading(false);
      // eslint-disable-next-line 
      dispatch({ type: FETCH_END });
    });
    // eslint-disable-next-line 
  }, []); // * note that [] is just run once

  const onSave = (data) => {
    settingProvider.saveSettings({ data })
      .then(() => {
        setSettings(data);
        notify("resources.settings.notifications.save_success");
      })
      .catch(error => {
        logger.error(error.message);

        notify(
          "resources.settings.notifications.save_failed",
          { type: "error" },
        );
      });
  };

  if (loading) return <Loading />;
  if (error) return <ErrorComponent error={error} />;

  return (
    <Card>
      <TabbedForm {...props}
        initialValues={settings}
        save={onSave}
      >
        <GeneralSettings value={settings} />
        <OTPSettings value={settings} />
        <LoginSettings value={settings} path="login" />
        <_3pidSettings value={settings} path="3pid" />
        <TwoFactorSettings value={settings} path="two-factor" />
        <BotSettings value={settings} path="bot" />
        <Title title={'resources.settings.name'} />
      </TabbedForm>
    </Card>
  )
};
