import { env } from "../env";
import { jsonClient } from "./dataProvider"

const serverNoticeProvider = {
  base_url: env.REACT_APP_AUTH_SERVER,
  async serverNoticeSendBulkMessage(dto) {
    const url = this.base_url + '/server-notice/send-message';
    const options = {
      method: 'Post',
      body: JSON.stringify(dto)
    }
    return await jsonClient(url, options);
  },
};

export default serverNoticeProvider;
