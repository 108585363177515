import { env } from "../env";
import { jsonClient } from "./dataProvider"
import { stringify } from "query-string";

function getSearchOrder(order) {
  if (order === "DESC") {
    return "b";
  } else {
    return "f";
  }
}

const botProvider = {
  base_url: env.REACT_APP_AUTH_SERVER,
  async getList(res, params) {
    const { search, enable } = params.filter;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const from = (page - 1) * perPage;
    const query = {
      size: perPage,
      page: page,
      search: search?.trim(),
      enable: enable,
      order_by: field,
      dir: getSearchOrder(order),
    };
    const url = this.base_url + `/bots?${stringify(query)}`;
    const options = {
      method: 'GET',
    }
    return await jsonClient(url, options).then(({ json }) => ({
      data: json['items'].map((item, index) => res.map(item, index, from)),
      total: res.total(json, from, perPage),
    }));
  },

  async getBot(botId) {
    const url = this.base_url + `/bots/${botId}`;
    const options = {
      method: 'GET',
    }
    return await jsonClient(url, options).then(({ json }) => ({
      data: json,
    }));
  },

  async createBot(data) {
    const url = this.base_url + `/bots`;
    let formData = new FormData();

    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('avatar', data.avatar);

    const options = {
      method: 'POST',
      header: {
        "Content-Type": "multipart/form-data;"
      },
      body: formData
    }
    return await jsonClient(url, options);
  },

  async updateBot(data) {
    const url = this.base_url + `/bots/${data.id}`;
    let formData = new FormData();

    for (var key in data) {
      if (data.hasOwnProperty(key) && key !== 'id') {
        formData.append(key, data[key]);
      }
    }

    const options = {
      method: 'PUT',
      header: {
        "Content-Type": "multipart/form-data;"
      },
      body: formData
    }
    return await jsonClient(url, options);
  },

  async deleteBot(botId) {
    const url = this.base_url + `/bots/${botId}`;
    const options = {
      method: 'DELETE',
    }
    return await jsonClient(url, options);
  },

  async generateShareKey(botId) {
    const url = this.base_url + `/share/bot/presigned-key/${botId}`;
    const options = {
      method: 'POST',
      body: JSON.stringify({})
    }
    return await jsonClient(url, options);
  },

  async generateBotToken(botId) {
    const url = this.base_url + `/bots/${botId}/gen-bot-token`;
    const options = {
      method: 'PUT',
      body: JSON.stringify({})
    }
    return await jsonClient(url, options);
  },

  async getListRoom(botId) {
    const url = this.base_url + `/bots/${botId}/rooms`;
    const options = {
      method: 'GET',
    }
    return await jsonClient(url, options);
  },

  async leaveRooms(botId, roomIds) {
    let ids = roomIds;
    if (typeof ids === 'string') {
      ids = [roomIds];
    }
    const url = this.base_url + `/bots/remove-from-rooms/${botId}`;
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        roomIds: ids,
      })
    }
    return await jsonClient(url, options);
  }
};

export default botProvider;
