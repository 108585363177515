import React, { useState, useEffect } from "react";
import {
  resetNotification,
  useLogin,
  useNotify,
  useTranslate,
} from "react-admin";
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { logger } from '../utils/logger';

import LoginStep from "./login-flow/Login";
import { Steps } from "../enum/ELoginFlow";
import authProvider from "../synapse/authProvider";
import SetupTwoFactor from "./login-flow/SetupTwoFactor";
import UpdatePassword from "./login-flow/UpdatePassword";
import VerifyTwoFactor from "./login-flow/VerifyTwoFactor";
import * as permissionsSaga from "../sagas/permissionsSaga";
import { setMetaData } from "../utils/storage";


const LoginFlow = ({ theme }) => {
  const classes = useStyles({ theme });
  const login = useLogin();
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useTranslate();
  const loginToken = /\?loginToken=([a-zA-Z0-9_-]+)/.exec(window.location.href);
  const [flow, setFlow] = useState({});
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [account, setAccount] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const notify = useNotify();
  const defaultAuthParams = {
    loginUrl: '/login',
    afterLoginUrl: '/',
  };

  if (loginToken) {
    const ssoToken = loginToken[1];
    logger.log("SSO token is", ssoToken);
    // Prevent further requests
    window.history.replaceState(
      {},
      "",
      window.location.href.replace(loginToken[0], "#").split("#")[0]
    );
    const baseUrl = localStorage.getItem("sso_base_url");
    localStorage.removeItem("sso_base_url");
    if (baseUrl) {
      const auth = {
        base_url: baseUrl,
        username: null,
        password: null,
        loginToken: ssoToken,
      };
      login(auth).catch(error => {
        alert(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
              ? "ra.auth.sign_in_error"
              : error.message
        );
        logger.error(error);
      });
    }
  }

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const metaData = JSON.parse(localStorage.getItem("meta_data"));
    if (accessToken && metaData?.requiredChangePassword) {
      const stepLogin = {
        nextStep: Steps.UPDATE_PASSWORD,
      };
      setFlow(stepLogin);
    } else if (accessToken) {
      goToHomePage();
    }
  }, []);

  const handleSubmit = async (stepLoginRO) => {
    if (stepLoginRO.nextStep === Steps.VERIFY_KEYS_DONE) {
      const dto = {
        loginToken: stepLoginRO.token,
        type: "org.matrix.login.jwt",
        createScalarToken: false,
      }
      setMetaData("requiredChangePassword", stepLoginRO?.requiredChangePassword);
      authProvider.login(dto).then(ret => {
        if (stepLoginRO?.requiredChangePassword) {
          const stepLogin = {
            nextStep: Steps.UPDATE_PASSWORD
          };
          setFlow(stepLogin);
          return;
        }
        goToHomePage();
        return ret;
      }).catch(() => {
        notify('synapseadmin.auth.login_flow.error');
        setFlow({
          nextStep: Steps.LOGIN,
        });
      });
      return;
    }
    setFlow(stepLoginRO);
  };

  const goToHomePage = () => {
    dispatch(resetNotification());
    dispatch({ type: permissionsSaga.FETCH_PERMISSIONS_SAGA });
    const redirectUrl = defaultAuthParams.afterLoginUrl;
    history.push(redirectUrl);
  }

  const onSessionExpired = () => {
    onBackToLogin();
    setIsOpenDialog(true);
  };

  const onBackToLogin = () => {
    const stepLogin = {
      nextStep: Steps.LOGIN,
    };
    setFlow(stepLogin);
  };

  const onCloseDialog = () => {
    setIsOpenDialog(false);
  }

  const updatePassword = async (value) => {
    setMetaData("requiredChangePassword", 0);
    goToHomePage();
  };

  const onUpdateAccount = (account) => {
    const { password, ...userInfo } = account;
    setAccount(account);
    setUserInfo(userInfo);
  }

  const renderSessionExpiredDialog = () => {
    return (
      <Dialog onClose={onCloseDialog} aria-labelledby="simple-dialog-title" open={isOpenDialog}>
        <DialogTitle id="alert-dialog-title">{translate('synapseadmin.auth.login_flow.error')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate('synapseadmin.auth.login_flow.session_expired')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className={classes.btnOK}
            color="primary"
            onClick={onCloseDialog}
            fullWidth
          >
            {translate("synapseadmin.auth.login_flow.ok")}
          </button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderFlow = (iStepLoginRO) => {
    let screenOfFlow = (<></>);
    switch (iStepLoginRO.nextStep) {
      case Steps.SETUP_TWO_FACTOR:
        screenOfFlow = (
          <SetupTwoFactor
            theme={theme}
            handleSubmit={handleSubmit}
            step={iStepLoginRO.nextStep}
            dataFlow={iStepLoginRO}
            onSessionExpired={onSessionExpired}
            userInfo={userInfo}
            onBackToLogin={onBackToLogin}
            isLoginFlow={true}
          />
        );
        break;
      case Steps.VERIFY_TWO_FACTOR:
        screenOfFlow = (
          <VerifyTwoFactor
            theme={theme}
            handleSubmit={handleSubmit}
            step={iStepLoginRO.nextStep}
            dataFlow={iStepLoginRO}
            onSessionExpired={onSessionExpired}
            userInfo={userInfo}
            onBackToLogin={onBackToLogin}
          />
        );
        break;
      case Steps.UPDATE_PASSWORD:
        screenOfFlow = (
          <UpdatePassword
            theme={theme}
            handleSubmit={handleSubmit}
            updatePassword={updatePassword}
            dataFlow={iStepLoginRO}
            oldPassword={account?.password}
          />);
        break;
      default:
        screenOfFlow = (
          <LoginStep
            theme={theme}
            handleSubmit={handleSubmit}
            onUpdateAccount={onUpdateAccount}
          />);
        break;
    }

    return screenOfFlow;
  };

  return (
    <div>
      {renderFlow(flow)}
      <div>
        {renderSessionExpiredDialog()}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  btnOK: {
    padding: '.5em 2em .5em 2em'
  }
})

export default LoginFlow;
