import { env } from "../env";
import { jsonClient } from "./dataProvider"

const roomProvider = {
    base_url: env.REACT_APP_AUTH_SERVER,
        async updateRoomPermissions(roomId, dto) {
            const url = this.base_url + `/rooms/${roomId}/permission`;
            const options = {
                method: 'PUT',
                body: JSON.stringify(dto)
            }
            return await jsonClient(url, options);
        },
};

export default roomProvider;