import englishMessages from "ra-language-english";

const en = {
  ...englishMessages,
  synapseadmin: {
    auth: {
      base_url: "Homeserver URL",
      welcome: "Welcome to Synapse-admin",
      welcome_brand: "Welcome to %{brand}-admin",
      server_version: "Synapse version",
      username_error: "Please enter fully qualified user ID: '@user:domain'",
      protocol_error: "URL has to start with 'http://' or 'https://'",
      url_error: "Not a valid Matrix server URL",
      sso_sign_in: "Sign in with SSO",
      login_flow: {
        ok: "OK",
        error: "An error occurred please try again later",
        session_expired: "Sorry, Your session has expired. Please try to log in again. Thank you!",
        update_password: {
          title: "Please update your account's new password",
          button_save: "Save",
        },
        user_deactivated: "Your account has been deactivated",
      },
    },
    users: {
      invalid_user_id: "Localpart of a Matrix user-id without homeserver.",
      invalid_phone_number:
        "Phone number must be in international format. E.g. 84123456789",
      tabs: { sso: "SSO" },
    },
    rooms: {
      tabs: {
        basic: "Basic",
        members: "Members",
        detail: "Details",
        permission: "Permissions",
      },
    },
    reports: { tabs: { basic: "Basic", detail: "Details" } },
  },
  import_users: {
    error: {
      at_entry: "At entry %{entry}: %{message}",
      error: "Error",
      required_field: "Required field '%{field}' is not present. Please check file data again and reload your browser",
      invalid_value:
        "Invalid value on line %{row}. '%{field}' field may only be 'true' or 'false'",
      unreasonably_big:
        "Refused to load unreasonably big file of %{size} megabytes",
      already_in_progress: "An import run is already in progress",
      id_exits: "ID %{id} already present",
      accept_file_extension: "Only CSV files are accepted",
    },
    title: "Import users via CSV",
    goToPdf: "Go to PDF",
    cards: {
      importstats: {
        header: "Import users",
        users_total:
          "%{smart_count} phone number in CSV file |||| %{smart_count} users account in CSV file",
        guest_count: "%{smart_count} guest |||| %{smart_count} guests",
        admin_count: "%{smart_count} admin |||| %{smart_count} admins",
      },
      conflicts: {
        header: "Conflict strategy",
        mode: {
          stop: "Stop on conflict",
          skip: "Show error and skip on conflict",
        },
      },
      ids: {
        header: "IDs",
        all_ids_present: "IDs present on every entry",
        count_ids_present:
          "%{smart_count} entry with ID |||| %{smart_count} entries with IDs",
        mode: {
          ignore: "Ignore IDs in CSV and create new ones",
          update: "Update existing records",
        },
      },
      passwords: {
        header: "Passwords",
        all_passwords_present: "Passwords present on every entry",
        count_passwords_present:
          "%{smart_count} entry with password |||| %{smart_count} entries with passwords",
        use_passwords: "Use passwords from CSV",
      },
      upload: {
        header: "Input CSV file",
        explanation: "You can download and adapt an example file here: ",
      },
      startImport: {
        simulate_only: "Simulate only",
        run_import: "Import",
      },
      results: {
        header: "Import results",
        total:
          "%{smart_count} entry in total |||| %{smart_count} entries in total",
        successful: "%{smart_count} entries successfully imported",
        skipped: "%{smart_count} entries skipped",
        download_skipped: "Download skipped records",
        with_error:
          "%{smart_count} entry with errors ||| %{smart_count} entries with errors",
        simulated_only: "Run was only simulated",
      },
    },
    note: {
      title: 'Note',
      examplePhone: 'Example Phone number User: %{phoneNumber}',
      inputPhoneExplanation: 'Please input Country code + Phone number (exclude 0): %{phoneNumber}'
    }
  },
  resources: {
    users: {
      name: "User |||| Users",
      email: "Email",
      msisdn: "Phone",
      threepid: "Phone",
      fields: {
        avatar: "Avatar",
        id: "User-ID",
        name: "Name",
        is_guest: "Guest",
        admin: "Administrator",
        deactivated: "Deactivated",
        guests: "Show guests",
        show_deactivated: "Show deactivated users",
        user_id: "Search user",
        displayname: "Displayname",
        username: "Username",
        phoneNumber: "Phone number",
        password: "Password",
        avatar_url: "Avatar URL",
        avatar_src: "Avatar",
        medium: "Medium",
        threepids: "Phone numbers",
        address: "Phone number",
        creation_ts_ms: "Creation timestamp",
        consent_version: "Consent version",
        auth_provider: "Provider",
        role: "Role",
        super_admin: "Super Administrator",
        sub_admin: "Sub Administrator",
      },
      helper: {
        deactivate: "You must provide a password to re-activate an account.",
        erase: "Mark the user as GDPR-erased",
        phone_include_country_code: "Please input Country code +  Phone number. Eg: %{phoneNumber}",
        delete_account: "Do you want to delete for this user?",
      },
      action: {
        erase: "Erase user data",
        delete_account: "Delete Account",
        copied: "Copied",
        empty: "Empty",
        generate: "Generate"
      },
      enums: {
        deactivated: {
          activated: "Activated",
          deactivated: "Deactivated",
          erased: "Deleted",
        },
      },
      existed: "Phone number already exists",
      existed_email: "Email already exists",
      existed_phone: "Phone number already exists",
      invalid_email: "Sorry, only letters (a-z), numbers (0-9), and periods (.) before character '@' are allowed.",
      reset_2fa_success: "Reset two factor successfully",
      reset_2fa_failed: "Reset two factor failed.",
      reset_2fa_label: "RESET 2FA",
      not_enable_2fa: "User has not set up 2FA",
      update_email_success: "Update email successfully",
      update_email_failed: "Update email failed.",
      email_already_exists: "Email already exists.",
      email_already_exists_content: "Email already exists! Please edit your email and try again.",
      invalid_displayname: "Displayname must be contains characters.",
      invalid_length_displayname: "Must be 64 characters or less",
      required_displayname: "Displayname is required",
      deactivated: "Deactivated user",
      deactivated_success: "Deactivated user successfully",
      deactivated_failed: "Deactivated user failed",
      activate: "Activate user",
      activate_success: "Activate user successfully",
      activate_failed: "Activate user failed",
      invalid_usename: "This username is invalid.",
      existed_username: "Username already exists",
      user_status: "User status",
      invalid_short_usename: "This username is too short.",
      invalid_length_usename: "The username must be between 5 and 32 characters.",
      invalid_char_usename: "Only a-z, 0-9, and underscores allowed.",
      invalid_password: "This password is invalid.",
      invalid_form: "The form is not valid. Please check for errors",
      save_error: "Save data failed. Please try again or contact administrator.",
      out_of_date: "This user is out of date, please reload the page in order to get new information.",
      update_success: "User updated",
      create_success: "User created",
    },
    rooms: {
      name: "Room |||| Rooms",
      fields: {
        room_id: "Room-ID",
        name: "Name",
        canonical_alias: "Alias",
        joined_members: "Members",
        joined_local_members: "Local members",
        joined_local_devices: "Local devices",
        state_events: "State events / Complexity",
        version: "Version",
        is_encrypted: "Encrypted",
        encryption: "Encryption",
        federatable: "Federatable",
        public: "Visible in room directory",
        creator: "Creator",
        join_rules: "Join rules",
        guest_access: "Guest access",
        history_visibility: "History visibility",
        topic: "Topic",
        avatar: "Avatar",
        view_members: "View members",
        view_system_messages: "View system messages",
      },
      helper: {
        forward_extremities:
          "Forward extremities are the leaf events at the end of a Directed acyclic graph (DAG) in a room, aka events that have no children. The more exist in a room, the more state resolution that Synapse needs to perform (hint: it's an expensive operation). While Synapse has code to prevent too many of these existing at one time in a room, bugs can sometimes make them crop up again. If a room has >10 forward extremities, it's worth checking which room is the culprit and potentially removing them using the SQL queries mentioned in #1760.",
      },
      enums: {
        join_rules: {
          public: "Public",
          knock: "Knock",
          invite: "Invite",
          private: "Private",
        },
        guest_access: {
          can_join: "Guests can join",
          forbidden: "Guests can not join",
        },
        history_visibility: {
          invited: "Since invited",
          joined: "Since joined",
          shared: "Since shared",
          world_readable: "Anyone",
        },
        unencrypted: "Unencrypted",
      },
      action: {
        erase: {
          title: "Delete room",
          content:
            "Are you sure you want to delete the room? This cannot be undone. All messages and shared media in the room will be deleted from the server!",
        },
      },
      notifications: {
        change_permissions_success: "Change permissions successfully",
        change_permissions_failed: "Change permissions failed. Please try again or contact administrator.",
      }
    },
    reports: {
      name: "Reported event |||| Reported events",
      fields: {
        id: "ID",
        received_ts: "report time",
        user_id: "announcer",
        name: "name of the room",
        score: "score",
        reason: "reason",
        event_id: "event ID",
        event_json: {
          origin: "origin server",
          origin_server_ts: "time of send",
          type: "event typ",
          content: {
            msgtype: "content type",
            body: "content",
            format: "format",
            formatted_body: "formatted content",
            algorithm: "algorithm",
          },
        },
      },
    },
    connections: {
      name: "Connections",
      fields: {
        last_seen: "Date",
        ip: "IP address",
        user_agent: "User agent",
      },
    },
    devices: {
      name: "Device |||| Devices",
      fields: {
        device_id: "Device-ID",
        display_name: "Device name",
        last_seen_ts: "Timestamp",
        last_seen_ip: "IP address",
      },
      action: {
        erase: {
          title: "Removing %{id}",
          content: 'Are you sure you want to remove the device "%{name}"?',
          success: "Device successfully removed.",
          failure: "An error has occurred.",
        },
      },
    },
    users_media: {
      name: "Media",
      fields: {
        media_id: "Media ID",
        media_length: "File Size (in Bytes)",
        media_type: "Type",
        upload_name: "File name",
        quarantined_by: "Quarantined by",
        safe_from_quarantine: "Safe from quarantine",
        created_ts: "Created",
        last_access_ts: "Last access",
      },
    },
    delete_media: {
      name: "Media",
      fields: {
        before_ts: "last access before",
        size_gt: "Larger then (in bytes)",
        keep_profiles: "Keep profile images",
      },
      action: {
        send: "Delete media",
        send_success: "Request successfully sent.",
        send_failure: "An error has occurred.",
      },
      helper: {
        send: "This API deletes the local media from the disk of your own server. This includes any local thumbnails and copies of media downloaded. This API will not affect media that has been uploaded to external media repositories.",
      },
    },
    protect_media: {
      action: {
        create: "Unprotected, create protection",
        delete: "Protected, remove protection",
        none: "In quarantine",
        send_success: "Successfully changed the protection status.",
        send_failure: "An error has occurred.",
      },
    },
    quarantine_media: {
      action: {
        name: "Quarantine",
        create: "Add to quarantine",
        delete: "In quarantine, unquarantine",
        none: "Protected from quarantine",
        send_success: "Successfully changed the quarantine status.",
        send_failure: "An error has occurred.",
      },
    },
    pushers: {
      name: "Pusher |||| Pushers",
      fields: {
        app: "App",
        app_display_name: "App display name",
        app_id: "App ID",
        device_display_name: "Device display name",
        kind: "Kind",
        lang: "Language",
        profile_tag: "Profile tag",
        pushkey: "Pushkey",
        data: { url: "URL" },
      },
    },
    servernotices: {
      name: "Server Notices",
      send: "Send server notices",
      fields: {
        body: "Message",
      },
      action: {
        send: "Send note",
        send_success: "Server notice successfully sent.",
        send_success_to_user: "Server notice will be sent to user selected.",
        send_failure: "An error has occurred.",
      },
      helper: {
        send: 'Sends a server notice to the selected users. The feature "Server Notices" has to be activated at the server.',
      },
    },
    user_media_statistics: {
      name: "Users' media",
      fields: {
        media_count: "Media count",
        media_length: "Media length",
      },
    },
    forward_extremities: {
      name: "Forward Extremities",
      fields: {
        id: "Event ID",
        received_ts: "Timestamp",
        depth: "Depth",
        state_group: "State group",
      },
    },
    room_state: {
      name: "State events",
      fields: {
        type: "Type",
        content: "Content",
        origin_server_ts: "time of send",
        sender: "Sender",
      },
    },
    room_directory: {
      name: "Room directory",
      fields: {
        world_readable: "guest users may view without joining",
        guest_can_join: "guest users may join",
      },
      action: {
        title:
          "Delete room from directory |||| Delete %{smart_count} rooms from directory",
        content:
          "Are you sure you want to remove this room from directory? |||| Are you sure you want to remove these %{smart_count} rooms from directory",
        erase: "Delete from room directory",
        create: "Publish in room directory",
        send_success: "Room successfully published.",
        send_failure: "An error has occurred.",
      },
    },
    matrix_stickers: {
      name: "Sticker Packs"
    },
    matrix_bots: {
      name: "Bots Management"
    },
    settings: {
      name: "Setting",
      tabs: {
        general: 'General',
        otp: 'OTP',
        login: 'Login',
        _3pid: '3PID',
        security: 'Security',
        bot: 'Bot'
      },
      fields: {
        send_otp_telegram: "Send OTP via Telegram",
        telegram_group_id: "Telegram Group ID",
        telegram_bot_url: "Telegram Bot URL",
        send_otp_twilio: "Send OTP via Twilio",
        twilio_account_sid: "Twilio Account SID",
        twilio_auth_token: "Twilio Auth Token",
        twilio_phone: "Twilio Phone",
        send_otp_email: "Send OTP via Email",
        smtp_host: "SMTP Host",
        smtp_username: "SMTP Username",
        smtp_password: "SMTP Password",
        smtp_send_from: "SMTP Send from",
        sso_google: "Login with Google Account",
        login_qr_code: "Login via QR code",
        login_manual: "Login Manual",
        login_email: "Login email",
        login_phone: "Login phone",
        allowed_cloudkit_domains: "Allowed Cloudkit domain(s)",
        domain: "Domain",
        _3pid_allow_to_change_email: "Allow user to change email",
        _3pid_allow_to_change_phone: "Allow user to change phone",
        two_factor_enable: "Enable required 2FA",
        ttl_key_share: "Shared link expiration time (seconds)",
        ttl_bot_share: "Shared key expiration time (seconds)",
        call_direct_via: "Call direct via:",
        matrix_turn_server: "Server 1 (Matrix TURN server)",
        jitsi_meet: "Server 2 (Jitsi Meet)",
        allow_to_export_chat: "Allow user to export chat",
        two_factor_label: "Two-Factor Authentication:",
        room_label: "Room:",
        user_label: "User:",
        force_auto_update_synchronization_label: "Force auto update app:",
        force_auto_update_synchronization: "Enable update synchronization",
      },
      validations: {
        required_telegram_options: 'Required config for Telegram',
        required_twilio_options: 'Required config for Twilio',
        regex_domain: 'Must be valid domain name',
        reset_2fa_title: 'Reset Two Factor',
        reset_2fa_content: 'Do you want to reset 2FA for this user?',
        required_method_login_title: 'Choose a Login Method!',
        required_method_login_content: 'You must select at least one login option: Login with Google Account or Login Manual (Phone or Email)',
        regex_integer: 'Must be a positive integer and 6 characters or less',
        deactivated_title: 'Deactivated user',
        activate_title: 'Activate user',
        deactivated_content: 'Do you want to deactivated for this user?',
        activate_content: 'Enter your display name to activate your account',
        required_call_via_title: 'Choose a Call Direct Method!',
        required_call_via_content: 'You must select at least one call option: Call direct via Matrix TURN server or Jitsi Meet',
      },
      actions: {
        save: "Save settings"
      },
      notifications: {
        save_success: "Saved settings successfully",
        save_failed: "Save settings failed. Please try again or contact administrator.",
      }
    },
    profile: {
      name: "Profile",
      tabs: {
        profile: "Profile",
        change_password: "Change Password",
        security: "Security",
      },
      fields: {
        username: "Username/Email",
        password: "Password",
        password_required: "Password is required",
        old_password: "Old Password",
        new_password: "New Password",
        re_enter_new_password: "Confirm New Password",
        old_password_required: "Old Password is required",
        new_password_required: "New Password is required",
        re_enter_new_required: "Confirm New Password is required",
        does_not_match_new_password: "New Password and Confirm New Password do not match",
        minlength_password: "Password must be at least 6 characters"
      },
      notifications: {
        save_success: "Saved new password successfully",
        save_failed: "Save new password failed. Please try again or contact administrator.",
        save_failed_wrong_old_password: "Old password is incorrect.",
        failed_to_fetch: "Failed to fetch data, please try again later."
      },
      security: {
        two_factor_title: "Two-Factor Authentication (2FA)",
        two_factor_title_description: "To protect your account, it is recommended to turn on 2FA",
        verified: "Verified",
        setup_two_factor: "Setup 2FA",
        cannot_get_status: "Cannot get security status",
      }
    },
    roles: {
      name: "Roles Management",
      tabs: {
        role: "Role",
        permissions: "Permissions",
        users: "Sub Admin Users",
      },
      fields: {
        namelist: "Name",
        name: "Name *",
        createdAt: "Created At",
        updatedAt: "Updated At",
        userPermissions: "User",
        roomPermissions: "Room",
        showOnlyAssignedUsersChange: "Show only assigned users"
      },
      actions: {
        add_role: "Add role",
        save: "Save",
        delete: "Delete",
        cancel: "Cancel",
        confirm: "Confirm",
      },
      validations: {
        delete_title: "Delete Role",
        delete_content: "Do you want to delete this role?",
        invalid_name: "Role name invalid.",
        required: "Name is required",
        existed: "Role name already exists",
        out_of_date: "This role is out of date, please reload the page in order to get new information.",
      },
      notifications: {
        save_success: "Saved successfully",
        save_failed: "Save failed. Please try again or contact administrator.",
        delete_success: "Deleted successfully",
        delete_failed: "Delete failed. Please try again or contact administrator.",
      },
      title: {
        edit: "Edit",
        create: "Create Role",
      },
    },
    bots: {
      name: "Bots Management",
      actions: {
        add_bot: "Add Bot",
        update_bot: "Update Bot",
        upload: "Upload",
        upload_edit: "Edit",
        delete_success: "The bot was deleted successfully!",
        change_status_success: "The status was changed successfully!",
        leave: "Leave",
        leave_confirm_title: "Leave room \"%{roomName}\"",
        leave_confirm_content: "Are you sure you want Bot to leave room \"%{roomName}\"?",
        leave_room_success: "Leave room successfully!",
      },
      tabs: {
        profile: "Bot",
        room: "Rooms",
        usage: "Usage",
        help: "Help"
      },
      title: {
        edit: "Edit Bot",
        create: "Create Bot",
        update: "Update Bot",
      },
      notifications: {
        image_invalid_type: "Unsupported format. Please try again.",
        image_max_size: "Upload image not exceeding 5MB. Please try again.",
        save_failed: "Save failed. Please try again or contact administrator.",
        save_success: "Saved successfully",
        created: "Created successfully",
        created_bot_token: "Created new bot token successfully",
        created_share_key: "Created share key successfully",
        generate_failed: "Generate failed",
        not_found: "Bot not found",
      },
      list: {
        avatar: "Avatar",
        bot_id: "Bot-ID",
        displayname: "Displayname",
        description: "Description",
        createdAt: "Creation timestamp",
        status: "Status",
        search_placeholder: "Bot-ID, Displayname, Description"
      },
      validations: {
        invalid_name: "Bot name invalid.",
        invalid_description: "Bot description invalid.",
        description_required: "Bot description is required.",
        name_required: "Bot name is required.",
        avatar_required: "The bot avatar is required.",
        invalid_length_description: "Must be 255 characters or less.",
        invalid_length_name: "Must be 64 characters or less.",
      },
      fields: {
        bot_id: "Bot-ID",
        displayname: "Displayname",
        description: "Description",
        createdAt: "Creation timestamp",
        status: "Enabled",
        bot_token: "Bot token",
        share_key: "Generate share key to add Bot",
        how_to_use: "How to use this bot token ?",
        no_result_found: "No result found",
        limit_direct_messages: "Limit direct messages",
        no_error_over_limit: "Not throw error when over limit",
        bot_broadcast_message: "Bot broadcast message",
        bot_direct_message: "Bot direct message",
        bot_request_status: "Bot request status",
        bot_format_message: "Syntax format message",
        syntax: "Syntax",
        rendered_output: "Rendered Output",
      },
      confirm: {
        title_change_bot_token: "Generate new bot token",
        content_change_bot_token: "Are you sure you want to generate new bot token ?",
      }
    },
    common: {
      validations: {
        regex_positive_integer: "Must be a positive integer",
      },
      failed_to_operate: "Failed to operator.",
      failed_to_operate_please_refresh: "Failed to operator. Please refresh page and try again.",
      failed_to_operate_please_contact: "Failed to operator. Please try again later or contact administrator.",
      show_more: "Show more",
      show_less: "Show less",
    }
  },
  registration_tokens: {
    name: "Registration tokens",
    fields: {
      token: "Token",
      valid: "Valid token",
      uses_allowed: "Uses allowed",
      pending: "Pending",
      completed: "Completed",
      expiry_time: "Expiry time",
      length: "Length",
    },
    helper: { length: "Length of the token if no token is given." },
  },
  two_factor: {
    name: 'Authenticator',
    filter: 'Not setup Authenticator',
    title: "Enable Google Authenticator",
    scan_qr: "Scan this QR code in the Authenticator app",
    enter_code: "Enter code",
    verify: "Verify",
    title_verify: "Verify Google Authenticator",
    code_invalid: "The Authenticator Code is invalid",
    cannot_setup: "Cannot setup Authenticator",
    cannot_get_qr: "Cannot get QR code",
    cannot_verify: "Cannot verify Authenticator",
    code_invalid_verify: "OTP is invalid. Please enter correct OTP code",
    enable_two_factor: "You have not set up Two-factor authentication",
    enabled: "Enabled",
  },
  permissions: {
    users_search_all: "Search all users",
    users_export: "Export user list",
    users_import: "Import user list",
    users_details: "View user details",
    users_erase: "Delete Account",
    users_reset_two_factor: "Reset 2FA",
    users_connection: "See user connection info",
    users_media: "See user media info",
    users_rooms: "See user rooms info",
    users_pushers: "See user pushers info",
    users_create: "Create new user",
    users_update: "Update user's profile",
    users_active: "Deactivate - Activate user",
    rooms_search: "Search all rooms",
    rooms_export: "Export room list",
    rooms_details: "See full room's info",
    rooms_update: "Update room's info",
    users_devices: "See user devices",
  },
};
export default en;
