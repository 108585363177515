import React, { Fragment, useState, useEffect, cloneElement } from "react";
import { connect } from "react-redux";
import {
  BooleanField,
  BulkDeleteButton,
  DateField,
  Datagrid,
  DeleteButton,
  Filter,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  SearchInput,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useTranslate,
  useNotify,
  // ExportButton,
  sanitizeListRestProps,
} from "react-admin";
import get from "lodash/get";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography, Chip } from "@material-ui/core";
import FastForwardIcon from "@material-ui/icons/FastForward";
import HttpsIcon from "@material-ui/icons/Https";
import NoEncryptionIcon from "@material-ui/icons/NoEncryption";
import PageviewIcon from "@material-ui/icons/Pageview";
import UserIcon from "@material-ui/icons/Group";
import ViewListIcon from "@material-ui/icons/ViewList";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EventIcon from "@material-ui/icons/Event";
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  RoomDirectoryBulkDeleteButton,
  RoomDirectoryDeleteButton,
} from "./RoomDirectory";
import dataProvider from "../synapse/dataProvider";
import roomProvider from "../synapse/roomProvider";
import { getRoleValue, isDefaultRole } from "../helper/rooms";
import { EPermission } from "../enum/EPermission";
import { hasPermission } from "../helper/permission";
import PermissionWrapper from "./common/PermissionWrapper";
import ExportButton from "./common/ExportButton";

const date_format = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

const useStyles = makeStyles(theme => ({
  helper_forward_extremities: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    margin: "0.5em",
  },
}));

function mapStateToProps(state) {
  return {
    roomFilters: state.admin.resources.rooms.list.params.displayedFilters,
    permissions: state.permissions.permissions,
  };
}

const GetStateRoomInfo = (data) => {
  return data.find(
    (e) => e.type === 'm.room.power_levels'
  )?.content;
}

const RoomPagination = props => (
  <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />
);

const EncryptionField = ({ source, record = {}, emptyText }) => {
  const translate = useTranslate();
  const value = get(record, source);
  let ariaLabel = value === false ? "ra.boolean.false" : "ra.boolean.true";

  if (value === false || value === true) {
    return (
      <Typography component="span" variant="body2">
        <Tooltip title={translate(ariaLabel, { _: ariaLabel })}>
          {value === true ? (
            <HttpsIcon data-testid="true" htmlColor="limegreen" />
          ) : (
            <NoEncryptionIcon data-testid="false" color="error" />
          )}
        </Tooltip>
      </Typography>
    );
  }

  return (
    <Typography component="span" variant="body2">
      {emptyText}
    </Typography>
  );
};

const RoomTitle = ({ record }) => {
  const translate = useTranslate();
  var name = "";
  if (record) {
    name = record.name !== "" ? record.name : record.id;
  }

  return (
    <span>
      {translate("resources.rooms.name", 1)} {name}
    </span>
  );
};

const RoomShowActions = ({ basePath, data, resource }) => {
  var roomDirectoryStatus = "";
  if (data) {
    roomDirectoryStatus = data.public;
  }
  
  return (
    <TopToolbar>
      {/* {roomDirectoryStatus === false && (
        <RoomDirectorySaveButton record={data} />
      )} */}
      {roomDirectoryStatus === true && (
        <RoomDirectoryDeleteButton record={data} />
      )}
      <PermissionWrapper names={[ EPermission.SUPER_ADMIN ]}>
        <DeleteButton
          basePath={basePath}
          record={data}
          resource={resource}
          mutationMode="pessimistic"
          confirmTitle="resources.rooms.action.erase.title"
          confirmContent="resources.rooms.action.erase.content"
        />
      </PermissionWrapper>
    </TopToolbar>
  );
};

export const RoomShowConnect = props => {
  const classes = useStyles({ props });
  const translate = useTranslate();
  const notify = useNotify();
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isDisable, setIsDisable] = useState(false);

  const DEFAULT_PAGINATION = 10;

  const [updatePermission, setUpdatePermission] = useState(false);

  const handleChangeOption = async (event) => {
    setIsDisable(true);
    setLoading({
      ...loading,
      [event.target.name]: true,
    });
    const selectedValue = event.target.checked;
    roomProvider.updateRoomPermissions(
      props.id,
      {
        key: event.target.name,
        value: getRoleValue(selectedValue),
      }
    ).then(() => {
      setPermissions({
        ...permissions,
        [event.target.name]: getRoleValue(selectedValue),
      });
      notify(
        "resources.rooms.notifications.change_permissions_success",
        { type: "success" },
      );
    }).catch(() => {
      notify(
        "resources.rooms.notifications.change_permissions_failed",
        { type: "error" },
      );
    }).finally(() => {
      setIsDisable(false);
      setLoading({
        ...loading,
        [event.target.name]: false,
      });
    });    
  };

  useEffect(() => {
    dataProvider.getManyReference('room_state', {
      id: props.id,
      pagination: DEFAULT_PAGINATION,
      sort: '',
    }).then((result) => {
      const powerLevels = GetStateRoomInfo(result.data);
      setPermissions({
          view_members: powerLevels?.view_members,
          view_system_messages: powerLevels?.view_system_messages,
      });
    }).catch(error => {
      notify(error.message, { type: "error" });
    });

    setUpdatePermission(
      hasPermission(
        [ 
          EPermission.ROOM_UPDATE,
        ],
        props.permissions,
      )
    );
    // eslint-disable-next-line 
  }, [props]);

  return (
    <Show {...props} actions={<RoomShowActions />} title={<RoomTitle />}>
      <TabbedShowLayout>
        <Tab label="synapseadmin.rooms.tabs.basic" icon={<ViewListIcon />}>
          <TextField source="room_id" />
          <TextField source="name" />
          <TextField source="canonical_alias" />
          <TextField source="creator" />
          {/* <ReferenceField source="creator" reference="users">
              <TextField source="id" />
          </ReferenceField> */}
        </Tab>

        <Tab
          label="synapseadmin.rooms.tabs.detail"
          icon={<PageviewIcon />}
          path="detail"
        >
          <TextField source="joined_members" />
          <TextField source="joined_local_members" />
          <TextField source="joined_local_devices" />
          <TextField source="state_events" />
          <TextField source="version" />
          <TextField
            source="encryption"
            emptyText={translate("resources.rooms.enums.unencrypted")}
          />
        </Tab>

        <Tab label="synapseadmin.rooms.tabs.members" icon={<UserIcon />}>
          <ReferenceManyField
            reference="room_members"
            target="room_id"
            addLabel={false}
          >
            <Datagrid
              style={{ width: "100%" }}
              // rowClick={(id, basePath, record) => "/users/" + id}
            >
              <TextField
                source="id"
                sortable={false}
                label="resources.users.fields.id"
              />
              {/* <ReferenceField
                label="resources.users.fields.displayname"
                source="id"
                reference="users"
                sortable={false}
                link=""
              >
                <TextField source="displayname" sortable={false} />
              </ReferenceField> */}
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab
          label="synapseadmin.rooms.tabs.permission"
          icon={<VisibilityIcon />}
          path="permission"
        >
          <BooleanField source="federatable" />
          <BooleanField source="public" />
          <SelectField
            source="join_rules"
            choices={[
              { id: "public", name: "resources.rooms.enums.join_rules.public" },
              { id: "knock", name: "resources.rooms.enums.join_rules.knock" },
              { id: "invite", name: "resources.rooms.enums.join_rules.invite" },
              {
                id: "private",
                name: "resources.rooms.enums.join_rules.private",
              },
            ]}
          />
          <SelectField
            source="history_visibility"
            choices={[
              {
                id: "invited",
                name: "resources.rooms.enums.history_visibility.invited",
              },
              {
                id: "joined",
                name: "resources.rooms.enums.history_visibility.joined",
              },
              {
                id: "shared",
                name: "resources.rooms.enums.history_visibility.shared",
              },
              {
                id: "world_readable",
                name: "resources.rooms.enums.history_visibility.world_readable",
              },
            ]}
          />
          <StyledFormControlLabel
            control={
              loading?.view_members ?
              <StyledCircularProgress />
              :
              <StyledSwitch
                checked={isDefaultRole(permissions?.view_members)}
                onChange={handleChangeOption}
                name="view_members"
                color="primary"
                disabled={isDisable || !updatePermission}
              />
            }
            label={translate("resources.rooms.fields.view_members")}
          />
          <StyledFormControlLabel
            control={
              loading?.view_system_messages ?
              <StyledCircularProgress />
              :
              <StyledSwitch
                checked={isDefaultRole(permissions?.view_system_messages)}
                onChange={handleChangeOption}
                name="view_system_messages"
                color="primary"
                disabled={isDisable || !updatePermission}
              />
            }
            label={translate("resources.rooms.fields.view_system_messages")}
          />
        </Tab>

        <Tab
          label={translate("resources.room_state.name", { smart_count: 2 })}
          icon={<EventIcon />}
          path="state"
        >
          <ReferenceManyField
            reference="room_state"
            target="room_id"
            addLabel={false}
          >
            <Datagrid style={{ width: "100%" }}>
              <TextField source="type" sortable={false} />
              <DateField
                source="origin_server_ts"
                showTime
                options={date_format}
                sortable={false}
              />
              <TextField source="content" sortable={false} />
              {/* <ReferenceField
                source="sender"
                reference="users"
                sortable={false}
              >
                <TextField source="id" />
              </ReferenceField> */}
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab
          label="resources.forward_extremities.name"
          icon={<FastForwardIcon />}
          path="forward_extremities"
        >
          <div className={classes.helper_forward_extremities}>
            {translate("resources.rooms.helper.forward_extremities")}
          </div>
          <ReferenceManyField
            reference="forward_extremities"
            target="room_id"
            addLabel={false}
          >
            <Datagrid style={{ width: "100%" }}>
              <TextField source="id" sortable={false} />
              <DateField
                source="received_ts"
                showTime
                options={date_format}
                sortable={false}
              />
              <NumberField source="depth" sortable={false} />
              <TextField source="state_group" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const RoomShow = connect(mapStateToProps)(RoomShowConnect);

const RoomBulkActionButtons = props => (
  <Fragment>
    {/* <RoomDirectoryBulkSaveButton {...props} /> */}
    <RoomDirectoryBulkDeleteButton {...props} />
    <BulkDeleteButton
      {...props}
      confirmTitle="resources.rooms.action.erase.title"
      confirmContent="resources.rooms.action.erase.content"
      mutationMode="pessimistic"
    />
  </Fragment>
);

const RoomFilter = ({ ...props }) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchInput source="search_term" alwaysOn variant="outlined" />
      <Chip
        label={translate("resources.rooms.fields.joined_local_members")}
        source="joined_local_members"
        defaultValue={false}
        style={{ marginBottom: 8 }}
      />
      <Chip
        label={translate("resources.rooms.fields.state_events")}
        source="state_events"
        defaultValue={false}
        style={{ marginBottom: 8 }}
      />
      <Chip
        label={translate("resources.rooms.fields.version")}
        source="version"
        defaultValue={false}
        style={{ marginBottom: 8 }}
      />
      <Chip
        label={translate("resources.rooms.fields.federatable")}
        source="federatable"
        defaultValue={false}
        style={{ marginBottom: 8 }}
      />
    </Filter>
  );
};

const RoomNameField = props => {
  const { source } = props;
  const record = useRecordContext(props);
  return (
    <span>{record[source] || record["canonical_alias"] || record["id"]}</span>
  );
};

RoomNameField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const ActionsBar = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
      })}
      <PermissionWrapper names={[ EPermission.ROOM_EXPORT ]}>
        <ExportButton 
          filterValues={filterValues}
          currentSort={currentSort}
          total={total}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          {...rest}
        />
      </PermissionWrapper>
  </TopToolbar>
);

const FilterableRoomList = ({ roomFilters, dispatch, permissions, ...props }) => {
  const filter = roomFilters;
  const localMembersFilter =
    filter && filter.joined_local_members ? true : false;
  const stateEventsFilter = filter && filter.state_events ? true : false;
  const versionFilter = filter && filter.version ? true : false;
  const federateableFilter = filter && filter.federatable ? true : false;

  return (
    <List
      {...props}
      pagination={<RoomPagination />}
      sort={{ field: "name", order: "ASC" }}
      filters={<RoomFilter />}
      bulkActionButtons={
        hasPermission(
          [ 
            EPermission.SUPER_ADMIN,
          ],
          permissions,
        ) && <RoomBulkActionButtons />
      }
      actions={<ActionsBar />}
    >
      <Datagrid 
        rowClick={ 
          hasPermission(
            [ 
              EPermission.ROOM_DETAILS
            ],
            permissions
          ) && "show"
        }
      >
        <EncryptionField
          source="is_encrypted"
          sortBy="encryption"
          label={<HttpsIcon />}
        />
        <RoomNameField source="name" />
        <TextField source="joined_members" />
        {localMembersFilter && <TextField source="joined_local_members" />}
        {stateEventsFilter && <TextField source="state_events" />}
        {versionFilter && <TextField source="version" />}
        {federateableFilter && <BooleanField source="federatable" />}
        <BooleanField source="public" />
      </Datagrid>
    </List>
  );
};

export const RoomList = connect(mapStateToProps)(FilterableRoomList);

const StyledFormControlLabel = withStyles({
  root: {
    flexDirection: 'column-reverse',
    marginLeft: '0',
    alignItems: 'flex-start',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '0',
    fontSize: '12.2px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1',
    letterSpacing: '0.00938em',
    marginTop: '8px',
  },
})(FormControlLabel);

const StyledSwitch = withStyles({
  root: {
    marginLeft: '-10px',
  }
})(Switch);

const StyledCircularProgress = withStyles({
  root: {
    width: '30px !important',
    height: '30px !important',
    padding: '5px',
  }
})(CircularProgress);