import React, { useState } from "react";
import { Box } from "@mui/material";
import { env } from "../../../env";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@mui/material/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslate } from "react-admin";
import { EBotHelp } from "../../../enum/EBotHelp";

const host = env.REACT_APP_PUBLIC_API_SERVER;
const brand = env.REACT_APP_BRAND;
const postMethod = "POST";
const getMethod = "GET";
const broadcastEndpoint = botToken => `/v1/bot/${botToken}/sendMessage`;
const directEndpoint = botToken => `/v1/bot/${botToken}/directMessage`;
const requestStatusEndpoint = requestId => `/v1/request/${requestId}/status`;

const message = `This message was sent from ${brand} Bot`;
const users = "user1@example.com,user2@example.com,user3@example.com";
const requestIdExample = "1";

const broadcastContentAPI = `Host URL: ${host}
Method: ${postMethod}
Endpoint: ${broadcastEndpoint("<bot_token>")}
Content-type: application/x-www-form-urlencoded | application/json
Body:
  text="${message}"
`;

const directContentAPI = `Host URL: ${host}
Method: ${postMethod}
Endpoint: ${directEndpoint("<bot_token>")}
Content-type: application/x-www-form-urlencoded | application/json
Body:
  text="${message}"
  users="${users}"
`;

const requestStatusAPI = `Host URL: ${host}
Method: ${getMethod}
Endpoint: ${requestStatusEndpoint("<request_id>")}`;

const contentAPI = type => {
  switch(type) {
    case EBotHelp.M_BROADCAST_MESSAGE:
      return broadcastContentAPI
    case EBotHelp.M_DIRECT_MESSAGE:
      return directContentAPI
    case EBotHelp.M_REQUEST_STATUS:
        return requestStatusAPI
    default:
      return ""
  }
}

const contentCurl = (botToken, type) => {
  switch(type) {
    case EBotHelp.M_BROADCAST_MESSAGE:
      return `curl -X ${postMethod} ${host}${broadcastEndpoint(
        botToken
      )} -d text="${message}"`; // Remove white space
    case EBotHelp.M_DIRECT_MESSAGE:
      return `curl -X ${postMethod} ${host}${directEndpoint(
        botToken
      )} -d text="${message}" -d users="${users}"`; // Remove white space
    case EBotHelp.M_REQUEST_STATUS:
      return `curl -X ${getMethod} ${host}${requestStatusEndpoint(
        requestIdExample
      )}`; // Remove white space
    default:
      return ""
  }

} ;

const useStyles = makeStyles({
  container: {
    margin: 0,
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
    padding: "8px",
  },
  codeBlock: {
    background: "#ececec",
    marginBottom: "10px",
  },
  codeBlockCurl: {
    paddingRight: "10px",
    marginBottom: "0px",
  },
});

const HowToUseBotToken = props => {
  const classes = useStyles();
  const translate = useTranslate();

  const [isCopied, setIsCopied] = useState(false);
  const [copyText, setCopyText] = useState("Copied");

  const onCopy = (event) => {
    event.preventDefault();
    setCopyText(
      props.botToken
        ? translate("resources.users.action.copied")
        : translate("resources.users.action.empty")
    );
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <>
      <Box className={classes.codeBlock}>
        <pre className={classes.container}>
          <code>{contentAPI(props.type)}</code>
        </pre>
      </Box>
      <Box className={[classes.codeBlock, classes.codeBlockCurl]}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <pre className={classes.container}>
            <code>{contentCurl(props.botToken, props.type)}</code>
          </pre>
          <CopyToClipboard text={contentCurl(props.botToken, props.type)}>
            <span
              className={classes.copyClipboard}
              onClick={event => onCopy(event)}
            >
              <Tooltip title={copyText} open={isCopied} placement="top">
                <FileCopyIcon style={{ fontSize: "20", cursor: "pointer" }} />
              </Tooltip>
            </span>
          </CopyToClipboard>
        </Box>
      </Box>
    </>
  );
};

export default HowToUseBotToken;
