import PropTypes from "prop-types";
import { BooleanInput, Filter, SearchInput, SelectInput } from "react-admin";
import React from "react";
import { connect } from "react-redux";
import { EPermission } from "../../enum/EPermission";
import { hasPermission } from "../../helper/permission";

const choices = [
  { id: "true", name: "Enabled" },
  { id: "false", name: "Disabled" },
]

export const UserFilter = props => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn variant="outlined" placeholder="User-ID" />
    {
      hasPermission([EPermission.SUPER_ADMIN], props.permissions.permissions) &&
      <BooleanInput
        label="resources.users.fields.admin"
        source="admin"
        alwaysOn
      />
    }
    <BooleanInput
      label="resources.users.fields.show_deactivated"
      source="deactivated"
      alwaysOn
    />
    <SelectInput
      source="two_factor"
      label="two_factor.name"
      variant="outlined"
      choices={choices}
      resettable={false}
      emptyText="All"
      emptyValue="All"
      alwaysOn
    />
  </Filter>
);

UserFilter.propTypes = {
  permissions: PropTypes.object,
}

const mapStateToProps = function (state) {
  return {
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps)(UserFilter);
